import { QueryFunctionContext, useQuery, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";

import { AppointmentSearchOption } from "components";
import { NavBarSearchKeys } from "components/NavBarSearch/queryKeys";
import { AppointmentSearchResults, getFormattedAndSortedSearchResults } from "components/NavBarSearchAppointments/util";
import ENV from "config/Env";
import { DealerLocation } from "models";
import ApiInstance from "util/Api";
import { IBackendQueryKey } from "util/keyFactory";

export type SearchResultsData = {
  currentLocation: AppointmentSearchOption[];
  otherLocations: AppointmentSearchOption[];
};

export const useAppointmentSearchResults = (
  searchTerm: string,
  isKeyLoopLocation: boolean,
  isNextLaneLocation: boolean,
  isWincarLocation: boolean,
  selectedLocation?: DealerLocation
) => {
  const queryClient = useQueryClient();

  const fetchSearch = async ({ queryKey }: QueryFunctionContext<ReadonlyArray<IBackendQueryKey>>) => {
    const { baseUrl, endpoint, params } = queryKey[0];
    const res = await ApiInstance.post(endpoint, params, baseUrl);

    if (!isKeyLoopLocation && !isNextLaneLocation && !isWincarLocation) res.data = { claire: res.data } as AppointmentSearchResults;

    const formattedSearchResults = getFormattedAndSortedSearchResults(res.data);

    return {
      currentLocation: formattedSearchResults.filter(appointment => !appointment.dealer_location || appointment?.dealer_location_id === selectedLocation?.id),
      otherLocations: formattedSearchResults.filter(appointment => appointment.dealer_location && appointment?.dealer_location_id !== selectedLocation?.id)
    } as SearchResultsData;
  };

  let baseUrl = ENV.apiBase;
  if (isKeyLoopLocation) baseUrl = ENV.keyloopBaseURL;
  else if (isNextLaneLocation) baseUrl = ENV.nextLaneBaseUrl;
  else if (isWincarLocation) baseUrl = ENV.wincarBaseURL;

  const params = {
    dealer_id: selectedLocation?.dealer_id,
    dealer_location_id: selectedLocation?.id,
    limit: 20,
    page: 0,
    term: searchTerm
  };

  const queryKey = NavBarSearchKeys.appointmentSearchResults(baseUrl, params);
  const { data, refetch, error } = useQuery({ queryKey, queryFn: fetchSearch, enabled: false });

  if (error) toast.error(error.message);

  return { data, refetch, reset: () => queryClient.resetQueries({ queryKey }) };
};
