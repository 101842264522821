import { ExactCategory, ExactStandaloneLicense } from "models";

export enum LOCATION_LICENSE_SUBPAGE {
  MonthlyLicenses = 0,
  OneTimeLicenses,
  Invoices
}

export enum INVOICING_OPTIONS {
  Monthly = 0,
  OnceOnDate,
  OnceInBillingCycle
}

export enum LICENSE_CATEGORY_TYPE {
  NotSet = 0,
  ManufacturerWarrantyManagerReceptionistDealerAdmin,
  SupervisorCallCenter,
  CounterTablet,
  GoogleAccount,
  Mechanic,
  DataHosting,
  ClientCommunicationModule,
  SMS,
  KeyLocker,
  TyreScanner,
  TyreTeam,
  Tablet,
  LocationStartupCosts,
  DealerStartupCosts,
  OneIStartupCosts,
  CombiUser
}

export type LicenseItem = {
  ID: string;
  StandardSalesPrice: number;
  Description: string;
  Code: string;
  Country: string;
  Language: string;
};

export type PaginatedHistoricalInvoices = {
  results: HistoricalInvoice[];
  __next?: string;
};

export type HistoricalInvoice = {
  ID: number;
  AmountFC: number;
  Modified: string;
  Subject: string;
};

export type LicenseResponse = {
  categories?: ExactCategory[];
  standalone_licenses?: ExactStandaloneLicense[];
};

export type Licenses = {
  monthlyCategories: ExactCategory[];
  monthlyStandalones: ExactStandaloneLicense[];
  oneTimeCategories: ExactCategory[];
  oneTimeStandalones: ExactStandaloneLicense[];
};

export type StandaloneLicenceDeletionMutationArgs = {
  standalone_license_id: number;
};

export type LicenseData = {
  id: number;
  categoryID: number | null;
  exact_item_id: string;
  code: string;
  name: string | null;
  category: string | null;
  quantity: number;
  price: number;
  description: string | null;
  totalPrice: number;
  invoiceOn?: Date | null;
  tablet_model?: string | null;
};

export interface CategoryLicenseMutationArgs {
  dealer_location_id: number;
  enabled: boolean;
}

export interface PdfDownloadArgs {
  document_id: number;
  dealer_location_id: number;
}

export interface Pane {
  menuItem: string;
  content: React.ReactNode;
}

export interface CategoryModalLicense {
  search: string;
  searchedItems: LicenseItem[];
  item: LicenseItem | null;
  price: number;
  description: string | null;
  categoryLicenseID: number;
  isSearchDropdownOpen: boolean;
}

export interface StandaloneModalLicense {
  search: string;
  searchedItems: LicenseItem[];
  item: LicenseItem | null;
  price: number;
  description: string | null;
  quantity: number;
  isSearchDropdownOpen: boolean;
}
