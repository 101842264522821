import moment from "moment";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Checkbox, Dropdown, Icon, Label, Popup } from "semantic-ui-react";

import { CUSTOM_CONFIRM_TYPES, CustomConfirm, useCan } from "components";
import { useDealersLocations, useUser } from "hooks";
import { Appointment, DMS } from "models";
import { CustomerCommunicationButtons, DeskCommunication } from "modules/AppointmentDetails/components";
import KeyLabelModal from "modules/AppointmentDetails/components/IndicatorsAction/components/KeyLabelModal";
import "modules/AppointmentDetails/components/IndicatorsAction/IndicatorsAction.scss";
import { useDetachKioskLabelMutation, useIndicator, useUpdateAppointment } from "modules/AppointmentDetails/hooks";
import { PRINT_SIZES, getPreference, setPreference } from "util/common";
import { ITranslation } from "util/interfaces";

type Options = {
  key: string;
  icon: React.ReactNode;
  value: string;
  text?: string;
};

const orangeColor = "#f2711c";

const starOptions: Options[] = [
  { key: "green", icon: <Icon className="star" color={"green"} />, value: "green" },
  { key: "blue", icon: <Icon className="star" color={"blue"} />, value: "blue" },
  { key: orangeColor, icon: <Icon className="star" color={"orange"} />, value: orangeColor },
  { key: "red", icon: <Icon className="star" color={"red"} />, value: "red" }
];

type IndicatorsActionProps = {
  data: Appointment;
  showVat?: boolean;
  onSwitchShowVat?: () => void;
};

export const IndicatorsAction = ({ data, showVat = false, onSwitchShowVat }: IndicatorsActionProps) => {
  const { appointmentMoneyIndicatorMutation, appointmentStarIndicatorMutation, appointmentShopIndicatorMutation } = useIndicator(data.id);
  const { dmsRefreshMutation } = useUpdateAppointment(data.id);
  const detachKioskMutation = useDetachKioskLabelMutation(data.id);
  const [showCancelModal, setShowCancelModal] = useState(false);

  const t = useTranslation().t as ITranslation;
  const user = useUser();
  const { selectedLocation } = useDealersLocations();
  const canUpdateAppointments = useCan("update", "appointments");

  const customerFirstnameSurname = (
    data.customer_owner
      ? `${data.customer_owner.firstname} ${data.customer_owner.surname}`
      : data.customer_driver
        ? `${data.customer_driver.firstname} ${data.customer_driver.surname}`
        : data.customer_contract
          ? `${data.customer_contract.firstname} ${data.customer_contract.surname}`
          : ""
  ).trim();

  const cartOptions: Options[] = [
    { key: "green", icon: <Icon className="shopping cart" color={"green"} />, value: "green", text: `${t("v8_same_day").message || "Same day"}` },
    { key: "blue", icon: <Icon className="shopping cart" color={"blue"} />, value: "blue", text: `${t("v8_to_order").message || "To Order"}` },
    { key: orangeColor, icon: <Icon className="shopping cart" color={"orange"} />, value: orangeColor, text: `${t("v8_next_day").message || "Next Day"}` },
    { key: "red", icon: <Icon className="shopping cart" color={"red"} />, value: "red", text: `${t("v8_back_order").message || "Back Order"}` },
    { key: "yellow", icon: <Icon className="shopping cart yellow-shop" />, value: "yellow", text: `${t("v8_arrived").message || "Arrived"}` }
  ];

  const [carInShopPrintVisible, setCarInShopPrintVisible] = useState(false);
  const [keyLabelPrintSize, setKeyLabelPrintSize] = useState(getPreference("preferences-key-label-print-size", PRINT_SIZES.A4));
  const handleStarUpdate = (color: string) => {
    if (data.is_star && data.is_star_color === color) return appointmentStarIndicatorMutation.mutate({ is_star: false, is_star_color: "" });
    appointmentStarIndicatorMutation.mutate({
      is_star: true,
      is_star_color: color
    });
  };

  const handleShopUpdate = (color: string) => {
    if (data.is_shop && data.is_shop_color === color) return appointmentShopIndicatorMutation.mutate({ is_shop: false, is_shop_color: "" });
    appointmentShopIndicatorMutation.mutate({
      is_shop: true,
      is_shop_color: color
    });
  };

  const handleMoney = () => {
    if (!canUpdateAppointments) return;

    appointmentMoneyIndicatorMutation.mutate({
      is_money: !data.is_money
    });
  };

  const handleChangeKeyLabelPrintSize = (value: PRINT_SIZES) => {
    setKeyLabelPrintSize(value);
    setPreference("preferences-key-label-print-size", value as PRINT_SIZES);
  };

  const handleCloseCarInShopPrintModal = () => setCarInShopPrintVisible(false);

  const handleBDDRefresh = () => {
    dmsRefreshMutation.mutate(data);
  };

  const canRefreshDMS =
    selectedLocation && [DMS.AutoFlex, DMS.KeyLoopJobs, DMS.KeyLoopMenus, DMS.WincarApi].includes(selectedLocation.dms_id) && !data.dms_nr.startsWith("claire_");

  const onRequestConfirmCloseModal = () => setShowCancelModal(false);

  const handleDetachKiosk = () => {
    detachKioskMutation.mutate();
    setShowCancelModal(false);
  };

  const handleConfirmModal = () => setShowCancelModal(true);

  const cartOptionsWithPopup = cartOptions.map(option => ({
    key: option.value,
    text: <Popup content={option.text} trigger={option.icon} />,
    value: option.value
  }));

  return (
    <div className="IndicatorsAction">
      {data.kiosk_label_number && (
        <Label className="IndicatorsAction-kiosk-label" onClick={handleConfirmModal}>
          <Icon className="tag" />
          <span>{data.kiosk_label_number}</span>
        </Label>
      )}
      {canRefreshDMS && data?.last_dms_update && (
        <div className="dms-container">
          {canRefreshDMS && <Icon className="arrow rotate" color="green" onClick={handleBDDRefresh} />}
          <p>
            <strong>{t("v8_dms").message || "DMS"}</strong>
            {data?.last_dms_update && moment(data.last_dms_update).fromNow()}
          </p>
        </div>
      )}

      {selectedLocation?.desk_check_in_enabled && user?.is_counter_tablet_user && <DeskCommunication appointment={data} />}

      <CustomerCommunicationButtons appointment={data} />

      <Button className="-appointment-status icon" size="small" onClick={() => setCarInShopPrintVisible(true)}>
        <Icon className="print" color="limegreen" />
      </Button>

      <Button className="-appointment-status icon" size="small" onClick={handleMoney}>
        <Icon className="dollar sign" color={data.is_money ? "limegreen" : "grey"} disabled={!useCan("update-billing", "appointments")} />
      </Button>

      <div className="container">
        <Dropdown
          className="status-dropdown"
          icon={null}
          selectOnBlur={false}
          disabled={!useCan("update-star", "appointments")}
          trigger={
            <div className="list-container">
              <Icon className="star" color={data.is_star_color === orangeColor ? "orange" : data.is_star_color} />
              <Icon className="chevron down black" size="tiny" />
            </div>
          }
        >
          <Dropdown.Menu>
            {starOptions.map(star => (
              <Dropdown.Item onClick={() => handleStarUpdate(star.value)} key={star.key} icon={star.icon} selected={data.is_star_color === star.value} />
            ))}
          </Dropdown.Menu>
        </Dropdown>
      </div>

      <div className="container">
        <Dropdown
          className="status-dropdown"
          icon={null}
          selectOnBlur={false}
          disabled={!useCan("update-shop", "appointments")}
          trigger={
            data.is_shop_color ? (
              <Popup
                content={cartOptions.find(option => option.key === data.is_shop_color)?.text}
                trigger={
                  <div className="list-container">
                    <Icon
                      className={`shopping cart ${data.is_shop_color === "yellow" ? "yellow-shop" : ""}`}
                      color={data.is_shop_color === orangeColor ? "orange" : data.is_shop_color}
                    />
                    <Icon className="chevron down black" size="tiny" />
                  </div>
                }
              />
            ) : (
              <div className="list-container">
                <Icon className="shopping cart" />
                <Icon className="chevron down black" size="tiny" />
              </div>
            )
          }
        >
          <Dropdown.Menu>
            {cartOptionsWithPopup.map(cart => (
              <Dropdown.Item onClick={() => handleShopUpdate(cart.value)} key={cart.key} icon={cart.text} selected={data.is_shop_color === cart.value} />
            ))}
          </Dropdown.Menu>
        </Dropdown>
      </div>

      <div className="container">
        <p>{t("v8_VAT").message || "VAT"}</p>
        <Checkbox toggle checked={showVat} onChange={onSwitchShowVat} />
      </div>

      <KeyLabelModal
        customerName={customerFirstnameSurname}
        appointment={data}
        handleChangeKeyLabelPrintSize={handleChangeKeyLabelPrintSize}
        keyLabelPrintSize={keyLabelPrintSize}
        handleCloseCarInShopPrintModal={handleCloseCarInShopPrintModal}
        carInShopPrintVisible={carInShopPrintVisible}
      />
      <CustomConfirm
        customTitle={t("v8_key_label_warning").message || "Key Label Warning"}
        type={CUSTOM_CONFIRM_TYPES.Warning}
        allowCustomReason
        isOpen={showCancelModal}
        customClass={"CancelAppointmentModal"}
        handleCancel={onRequestConfirmCloseModal}
        handleConfirm={handleDetachKiosk}
        isLoading={detachKioskMutation.isPending}
        confirmMsg={t("v8_confirm_disconnect_key_from_appointment").message || "Do you want to disconnect the key label from this WO?"}
        cancelButtonText={t("v8_no").message || "No"}
        confirmButtonText={t("v8_yes").message || "Yes"}
      />
    </div>
  );
};
