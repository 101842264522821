import moment from "moment";
import { useMemo, useState } from "react";
import { GridColumn } from "semantic-ui-react";

import {
  ActionModalSelectorData,
  AttachmentPreview,
  DATE_FORMATS,
  SNOOZE_DEPARTMENT,
  SNOOZE_STATUS,
  SNOOZE_TYPE,
  useGetSnoozeDepartmentData,
  useGetSnoozeStatusData,
  useGetSnoozeTypeData
} from "components";
import { Appointment, QuestionResult } from "models";
import SnoozeActions from "modules/AppointmentDetails/components/SnoozedItems/SnoozeActions";
import { getAllSnoozeAttachments } from "modules/AppointmentDetails/utils";

type SnoozeItemProps = {
  questionResult: QuestionResult;
  noBorder?: boolean;
  onSelect: (data: ActionModalSelectorData) => void;
  appointment: Appointment;
};

const SnoozeItem = ({ questionResult, appointment, noBorder, onSelect }: SnoozeItemProps) => {
  const [showMedia, setShowMedia] = useState(false);

  const { allSnoozeTypeData } = useGetSnoozeTypeData();
  const snoozeStatusData = useGetSnoozeStatusData();
  const snoozeDepartmentData = useGetSnoozeDepartmentData();

  const getSnoozeType = (snoozeType?: SNOOZE_TYPE) => {
    return allSnoozeTypeData.find(type => type.value === snoozeType)?.text || "";
  };

  const getSnoozeStatus = (snoozeStatus?: SNOOZE_STATUS) => {
    return snoozeStatusData.find(status => status.value === snoozeStatus)?.text || "";
  };

  const getSnoozeDepartment = (snoozeDepartment?: SNOOZE_DEPARTMENT) => {
    return snoozeDepartmentData.find(status => status.value === snoozeDepartment)?.text || "";
  };

  const toggleShowMedia = () => {
    setShowMedia(prev => !prev);
  };

  const attachments = useMemo(() => {
    return getAllSnoozeAttachments(questionResult.snooze_history);
  }, [questionResult.snooze_history]);

  const sortedSnoozeHistory = questionResult.snooze_history?.sort((history1, history2) => history2.created_on.localeCompare(history1.created_on));
  const snoozeItem = !!sortedSnoozeHistory?.length && sortedSnoozeHistory[0].snooze_status_id !== SNOOZE_STATUS.Deleted ? sortedSnoozeHistory[0] : null;

  return (
    <>
      <GridColumn className="content">
        <p>{questionResult.title}</p>
      </GridColumn>
      <GridColumn className="content">
        <p>{getSnoozeType(snoozeItem?.snooze_type_id)}</p>
      </GridColumn>
      <GridColumn className="content">
        <p>{snoozeItem?.snooze_date ? moment(snoozeItem.snooze_date).format(DATE_FORMATS.dateMonthYear) : ""}</p>
      </GridColumn>
      <GridColumn className="content">
        <p>{getSnoozeStatus(snoozeItem?.snooze_status_id)}</p>
      </GridColumn>
      <GridColumn className="content">
        <p>{getSnoozeDepartment(snoozeItem?.snooze_department_id)}</p>
      </GridColumn>
      <GridColumn className="content">
        <p>{snoozeItem?.note || ""}</p>
      </GridColumn>
      <GridColumn className="content last">
        <SnoozeActions
          data={questionResult}
          appointment={appointment}
          attachments={attachments}
          onSelect={onSelect}
          toggleShowMedia={toggleShowMedia}
          isIntervention={false}
        />
      </GridColumn>
      {showMedia && appointment && (
        <div className="snooze-images">
          <AttachmentPreview attachments={attachments} />
        </div>
      )}
      <div className={`divider ${noBorder ? "no-border" : ""}`} />
    </>
  );
};
export default SnoozeItem;
