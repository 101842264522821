import moment from "moment";
import { useEffect, useState } from "react";

import { DATE_FORMATS } from "components";
import { useDealersLocations } from "hooks";
import "modules/Appointments/components/Timer/Timer.scss";
import Timerslot from "modules/Appointments/components/Timer/Timerslot";

type FormattedSlot = {
  timeLeft: string;
  color: string;
};

export const Timer = () => {
  const { selectedLocation } = useDealersLocations();
  const [formattedTimeSlots, setFormattedTimeSlots] = useState<FormattedSlot[]>([]);

  const getFormattedTimeSlots = () => {
    const currentTime = moment();

    if (!selectedLocation?.timeslots) {
      setFormattedTimeSlots([]);
      return;
    }

    const formattedTimeSlots = selectedLocation?.timeslots
      .filter(time => time.active)
      .sort((a, b) => moment(b.deadline).diff(moment(a.deadline)))
      .map(slot => {
        const deadline = moment(slot.deadline).set({
          year: currentTime.year(),
          month: currentTime.month(),
          date: currentTime.date()
        });
        if (currentTime.isAfter(deadline)) {
          return {
            color: "red",
            timeLeft: "00:00"
          };
        } else if (
          (deadline.diff(currentTime, "minutes") > 0 && deadline.diff(currentTime, "minutes") < 30) ||
          (deadline.diff(currentTime, "seconds") > 0 && deadline.diff(currentTime, "seconds") <= 60)
        ) {
          const diff = moment.duration(deadline.diff(currentTime));
          const seconds = diff.seconds() >= 10 ? diff.seconds() : "0" + diff.seconds();
          return {
            color: "blue",
            timeLeft: `-${diff.minutes()}:${seconds}`
          };
        } else {
          return {
            color: "green",
            timeLeft: moment(slot.deadline).format(DATE_FORMATS.time)
          };
        }
      });

    setFormattedTimeSlots(formattedTimeSlots || []);
  };

  useEffect(() => {
    getFormattedTimeSlots();
  }, [selectedLocation?.id]);

  if (!formattedTimeSlots.length) {
    return null;
  }

  return (
    <div className="Timer">
      {formattedTimeSlots.map((s, k) => {
        return <Timerslot key={k} color={s.color} timeLeft={s.timeLeft} />;
      })}
    </div>
  );
};
