import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Checkbox, CheckboxProps, Icon, Popup } from "semantic-ui-react";
import { MenuItemProps } from "semantic-ui-react";
import { Menu } from "semantic-ui-react";

import { CUSTOM_CONFIRM_TYPES, Can, CustomConfirm, DATE_FORMATS, NavPortal } from "components";
import { useDealersLocations } from "hooks";
import { CategoryLicenseModal, InvoiceModal, InvoiceTable, MonthlyTable, OneTimeTable, StandaloneLicenseModal } from "modules/LocationLicenseOverview/components";
import { downloadInvoice, useCategoryLicenses, useInvoices, useItems, useLicenses, useSelectedStandaloneLicenceDelete } from "modules/LocationLicenseOverview/hooks";
import "modules/LocationLicenseOverview/LocationLicenseOverview.scss";
import { HistoricalInvoice, LOCATION_LICENSE_SUBPAGE, LicenseData, Pane } from "modules/LocationLicenseOverview/types";
import { calculateTotalCosts, getNextMonthlyInvoicingDate } from "modules/LocationLicenseOverview/utils";
import { ITranslation } from "util/interfaces";

const LocationLicenseOverview = () => {
  const t = useTranslation().t as ITranslation;
  const [paginatedURL, setPaginatedURL] = useState<string>("");
  const [currentInvoicePage, setCurrentInvoicePage] = useState<number>(0);
  const { invoices, isLoadingInvoices, paginatedInvoices } = useInvoices(paginatedURL, currentInvoicePage);
  const { items } = useItems();
  const { selectedLocation, selectedDealer } = useDealersLocations();
  const { data: licensesData, isLoadingLicenses } = useLicenses();
  const standaloneLicenceDeleteMutation = useSelectedStandaloneLicenceDelete();
  const { categoryLicensesEnabledMutation } = useCategoryLicenses();
  const [currentLocationLicenseSubpage, setCurrentLocationLicenseSubpage] = useState<LOCATION_LICENSE_SUBPAGE>(LOCATION_LICENSE_SUBPAGE.MonthlyLicenses);
  const [selectedLicense, setSelectedLicense] = useState<LicenseData | null>(null);
  const [selectedInvoice, setSelectedInvoice] = useState<HistoricalInvoice | null>(null);
  const [invoicePDF, setInvoicePDF] = useState<Blob | null>(null);
  const [showInvoiceModal, setShowInvoiceModal] = useState<boolean>(false);
  const [showStandaloneLicenseModal, setShowStandaloneLicenseModal] = useState<boolean>(false);
  const [showCategoryLicenseModal, setShowCategoryLicenseModal] = useState<boolean>(false);
  const [selectedDeleteStandaloneLicense, setSelectedDeleteStandaloneLicense] = useState<number | null>(null);

  const handleToggleCategoryLicences = (checked: boolean | undefined) => {
    if (selectedLocation) categoryLicensesEnabledMutation.mutate({ dealer_location_id: selectedLocation.id, enabled: !!checked });
  };

  const popupContent =
    t("v8_dealer_invoices_activated").message || "Invoices on dealer level are activated. This dealers invoices can be found on dealer license overview >Invoice History";

  const handleDeleteLicense = (standalone_license_id: number) => {
    setSelectedDeleteStandaloneLicense(standalone_license_id);
  };

  const handleConfirmDeleteLicense = async () => {
    if (selectedDeleteStandaloneLicense) await standaloneLicenceDeleteMutation.mutateAsync({ standalone_license_id: selectedDeleteStandaloneLicense });
    setSelectedDeleteStandaloneLicense(null);
  };

  const handleCancelDeleteLicense = () => {
    setSelectedDeleteStandaloneLicense(null);
  };

  const onLicenseRowClick = (rowData: LicenseData) => {
    setSelectedLicense(rowData);

    if (rowData.categoryID) setShowCategoryLicenseModal(true);
    else setShowStandaloneLicenseModal(true);
  };

  const onInvoiceRowClick = async (rowData: HistoricalInvoice) => {
    if (!selectedLocation?.id) return;

    setSelectedInvoice(rowData);
    const pdfData = await downloadInvoice({ document_id: rowData.ID, dealer_location_id: selectedLocation.id });
    setInvoicePDF(pdfData);
    setShowInvoiceModal(true);
  };

  const newStandaloneLicense = () => {
    setSelectedLicense(null);
    setShowStandaloneLicenseModal(true);
  };

  const handleChangeTab = (_e: React.MouseEvent<HTMLAnchorElement>, { index }: MenuItemProps) => {
    setCurrentLocationLicenseSubpage(index as LOCATION_LICENSE_SUBPAGE);
  };

  const handleCloseInvoiceModal = () => {
    setInvoicePDF(null);
    setSelectedInvoice(null);
    setShowInvoiceModal(false);
  };

  const invoicePageChange = (pageIndex: number) => {
    if (pageIndex < 0) return;
    if (paginatedInvoices && pageIndex in paginatedInvoices) {
      setCurrentInvoicePage(pageIndex);
      return;
    }
    if (!invoices?.__next) return;

    setPaginatedURL(invoices.__next);
    setCurrentInvoicePage(pageIndex);
  };

  const totalOneTimeCosts = useMemo(() => {
    return calculateTotalCosts(items, licensesData?.oneTimeCategories, licensesData?.oneTimeStandalones);
  }, [licensesData, items]);

  const totalMonthlyCosts = useMemo(() => {
    return calculateTotalCosts(items, licensesData?.monthlyCategories, licensesData?.monthlyStandalones);
  }, [licensesData, items]);

  const tabPanes: Pane[] = [
    {
      menuItem: t("v8_monthly").message || "Monthly",
      content: (
        <MonthlyTable
          items={items}
          licensesData={licensesData}
          isLoadingLicenses={isLoadingLicenses}
          onLicenseRowClick={onLicenseRowClick}
          handleDeleteLicense={handleDeleteLicense}
        />
      )
    },
    {
      menuItem: t("v8_once").message || "Once",
      content: (
        <OneTimeTable
          items={items}
          licensesData={licensesData}
          isLoadingLicenses={isLoadingLicenses}
          onLicenseRowClick={onLicenseRowClick}
          handleDeleteLicense={handleDeleteLicense}
        />
      )
    },
    {
      menuItem: t("v8_invoice_history").message || "Invoice History",
      content: (
        <InvoiceTable
          invoices={invoices}
          isLoadingInvoices={isLoadingInvoices}
          currentInvoicePage={currentInvoicePage}
          onPageChange={invoicePageChange}
          onInvoiceRowClick={onInvoiceRowClick}
        />
      )
    }
  ];

  return (
    <div className="LocationLicenseOverview mt-20">
      <NavPortal>
        <div className="LocationLicenseOverview-Navbar">
          <div className="LocationLicenseOverview-Navbar-Item">
            <span>{t("v8_total_monthly").message || "Total monthly"}:</span>
            {`€ ${totalMonthlyCosts.toFixed(2)}`}
          </div>
          <div className="LocationLicenseOverview-Navbar-Item">
            <span>{t("v8_total_once").message || "Total once"}:</span>
            {`€ ${totalOneTimeCosts.toFixed(2)}`}
          </div>
          <div className="LocationLicenseOverview-Navbar-Item">
            <span>{t("v8_total_this_month").message || "Total this month"}:</span>
            {`€ ${(totalMonthlyCosts + totalOneTimeCosts).toFixed(2)}`}
          </div>
          <div className="LocationLicenseOverview-Navbar-Item">
            <span>{t("v8_next_monthly_bill").message || "Next monthly bill"}:</span>
            {getNextMonthlyInvoicingDate().format(DATE_FORMATS.dateMonthYear)}
          </div>
          {selectedDealer?.exact_account_id && selectedDealer?.is_exact_invoicing_enabled && (
            <div className="LocationLicenseOverview-Navbar-Item">
              <Popup
                wide
                size="tiny"
                position="bottom right"
                offset={[0, 1]}
                trigger={<span className="span-ellipsis">{popupContent}</span>}
                content={<span>{popupContent}</span>}
              />
            </div>
          )}
        </div>
      </NavPortal>
      <div className="LocationLicenseOverview-Header">
        <div className="LocationLicenseOverview-Header-Tabs">
          <Menu secondary>
            {tabPanes.map((pane, index) => (
              <Menu.Item
                key={index}
                name={pane.menuItem}
                className="location-overview-header-tab"
                active={currentLocationLicenseSubpage === index}
                index={index}
                onClick={handleChangeTab}
              />
            ))}
          </Menu>
        </div>
        <Can I="write" the="exact-license">
          <div className="LocationLicenseOverview-Header-Actions">
            <div className="LocationLicenseOverview-toggle-container">
              <span> {t("v8_role_license_service").message || "Role / License / Service"} </span>
              <Checkbox
                toggle
                className="checkbox-automatic-licenses"
                checked={selectedLocation?.exact_is_category_licence_enabled}
                onChange={(_, { checked }: CheckboxProps) => handleToggleCategoryLicences(checked)}
              />
            </div>
            <div className="LocationLicenseOverview-add-container">
              <Button icon labelPosition="left" onClick={newStandaloneLicense}>
                <Icon className="plus" />
                {t("v8_add_license_manually").message || "Add license manually"}
              </Button>
            </div>
          </div>
        </Can>
      </div>
      <div className="LocationLicenseOverview-container mt-20">{tabPanes[currentLocationLicenseSubpage].content}</div>
      <StandaloneLicenseModal
        isOpen={showStandaloneLicenseModal}
        onClose={() => setShowStandaloneLicenseModal(false)}
        selectedLicense={selectedLicense}
        items={items}
        selectedTab={currentLocationLicenseSubpage}
        isLoadingLicenses={isLoadingLicenses}
      />
      <CategoryLicenseModal
        isOpen={showCategoryLicenseModal}
        onClose={() => setShowCategoryLicenseModal(false)}
        selectedLicense={selectedLicense}
        items={items}
        selectedTab={currentLocationLicenseSubpage}
        isLoadingLicenses={isLoadingLicenses}
        licensesData={licensesData}
      />
      <InvoiceModal isOpen={showInvoiceModal} onClose={handleCloseInvoiceModal} invoice={selectedInvoice as HistoricalInvoice} invoicePDF={invoicePDF} />
      <CustomConfirm
        type={CUSTOM_CONFIRM_TYPES.Danger}
        handleCancel={handleCancelDeleteLicense}
        isOpen={!!selectedDeleteStandaloneLicense}
        isLoading={standaloneLicenceDeleteMutation.isPending}
        confirmMsg={t("v8_delete_confirm_message").message || "Are you sure that you want to delete this? You can't undo this action?"}
        handleConfirm={handleConfirmDeleteLicense}
      />
    </div>
  );
};

export default LocationLicenseOverview;
