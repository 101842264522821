import { QueryFunctionContext, useQueryClient } from "@tanstack/react-query";
import moment from "moment";
import { useEffect, useMemo } from "react";

import { WebSocketMessageListener } from "components";
import { useDealersLocations, usePrevious, useRealTimeQuery, useUser } from "hooks";
import { Appointment, AppointmentNote, Check, CustomerCommunication } from "models";
import { useSelectedDate } from "modules/Appointments/hooks/useSelectedDate";
import { AppointmentsKeys } from "modules/Appointments/queryKeys";
import { useDesktopNotifications } from "modules/Auth/components/AccountSettings/hooks/index";
import ApiInstance from "util/Api";
import { updateAppointmentStatusIdentifier } from "util/appointmentUtils";
import { IBackendQueryKey } from "util/keyFactory";

export const useRealTimeAppointments = () => {
  const queryClient = useQueryClient();
  const { selectedLocation } = useDealersLocations();
  const user = useUser();
  const selectedDate = useSelectedDate();
  const prevSelectedLocationId = usePrevious(selectedLocation?.id);
  const prevSelectedDate = usePrevious(selectedDate);
  const { processDesktopNotifications, processNewAppointmentNotification } = useDesktopNotifications();

  const listeners = useMemo((): WebSocketMessageListener[] => {
    if (!selectedLocation?.id) return [];

    return [
      {
        model: "Appointment",
        filter: { dealer_location_id: Number(selectedLocation?.id) },
        permanent: true, // TODO: permanent should be set once when passing the list of listeners to useRealTimeQuery, so it can't be missed/added by mistake in the list
        callback: message => {
          const date = queryClient.getQueryData<Date>(AppointmentsKeys.selectedDate) || new Date();
          const appointments = [...(queryClient.getQueryData<Appointment[]>(AppointmentsKeys.list) ?? [])];
          const prevAppState = appointments.find(appointment => appointment.id === (message.data as Appointment).id);
          const appointment = updateAppointmentStatusIdentifier(message.data as Appointment);
          const appointmentIdx = appointments.findIndex(a => a.id === appointment.id);

          if (appointmentIdx >= 0) {
            processDesktopNotifications(prevAppState!, message.data as Appointment, message.user_id);
            appointments[appointmentIdx] = { ...appointments[appointmentIdx], ...appointment } as Appointment;
          } else if (moment(appointment.time_car_app).isSame(date, "day") || (appointment.is_pinned && moment(date).isSame(new Date(), "day"))) {
            appointments.push(appointment);
            processNewAppointmentNotification(message.data as Appointment);
          } else return;

          queryClient.setQueryData(AppointmentsKeys.list, appointments);
        }
      },
      {
        model: "AppointmentNote",
        action: "create",
        permanent: true,
        callback: message => {
          const appointments = queryClient.getQueryData<Appointment[]>(AppointmentsKeys.list);
          if (!appointments?.length) return;

          const note = message.data as AppointmentNote;
          const appIdx = appointments.findIndex(a => a.id === note.appointment_id);
          if (appIdx < 0 || appointments[appIdx].notes?.some(n => n.id === note.id)) return;

          queryClient.setQueryData(
            AppointmentsKeys.list,
            appointments.with(appIdx, { ...appointments[appIdx], notes: [...(appointments[appIdx].notes ?? []), note] } as Appointment)
          );
        }
      },
      {
        model: "AppointmentNote",
        action: "update",
        permanent: true,
        callback: message => {
          const appointments = queryClient.getQueryData<Appointment[]>(AppointmentsKeys.list);
          if (!appointments?.length) return;

          const note = message.data as AppointmentNote;
          const appIdx = appointments.findIndex(a => a.id === note.appointment_id);
          if (appIdx < 0 || !appointments[appIdx].notes?.length) return;

          const noteIdx = appointments[appIdx].notes.findIndex(n => n.id === note.id);
          if (noteIdx < 0) return;

          queryClient.setQueryData(
            AppointmentsKeys.list,
            appointments.with(appIdx, {
              ...appointments[appIdx],
              notes: appointments[appIdx].notes.with(noteIdx, { ...appointments[appIdx].notes[noteIdx], ...note } as AppointmentNote)
            } as Appointment)
          );
        }
      },
      {
        model: "AppointmentNote",
        action: "delete",
        permanent: true,
        callback: message => {
          const appointments = queryClient.getQueryData<Appointment[]>(AppointmentsKeys.list);
          if (!appointments?.length) return;

          const note = message.data as AppointmentNote;
          const appIdx = appointments.findIndex(a => a.id === note.appointment_id);
          if (appIdx < 0 || !appointments[appIdx].notes?.length) return;

          const noteIdx = appointments[appIdx].notes.findIndex(n => n.id === note.id);
          if (noteIdx < 0) return;

          queryClient.setQueryData(
            AppointmentsKeys.list,
            appointments.with(appIdx, {
              ...appointments[appIdx],
              notes: appointments[appIdx].notes.slice(0, noteIdx).concat(appointments[appIdx].notes.slice(noteIdx + 1))
            } as Appointment)
          );
        }
      },
      {
        model: "CustomerCommunication",
        action: "create",
        filter: { dealer_location_id: Number(selectedLocation?.id) },
        permanent: true,
        callback: message => {
          const appointments = queryClient.getQueryData<Appointment[]>(AppointmentsKeys.list);
          if (!appointments?.length) return;

          const customer_communication = message.data as CustomerCommunication;
          const appIdx = appointments.findIndex(a => a.id === customer_communication.appointment_id);
          if (appIdx < 0 || appointments[appIdx].customer_communication) return;

          queryClient.setQueryData(AppointmentsKeys.list, appointments.with(appIdx, { ...appointments[appIdx], customer_communication } as Appointment));
        }
      },
      {
        model: "CustomerCommunication",
        action: "update",
        filter: { dealer_location_id: Number(selectedLocation?.id) },
        permanent: true,
        callback: message => {
          const appointments = queryClient.getQueryData<Appointment[]>(AppointmentsKeys.list);
          if (!appointments?.length) return;

          const customer_communication = message.data as CustomerCommunication;
          const appIdx = appointments.findIndex(a => a.id === customer_communication.appointment_id);
          if (appIdx < 0 || !appointments[appIdx].customer_communication) return;

          queryClient.setQueryData(
            AppointmentsKeys.list,
            appointments.with(appIdx, {
              ...appointments[appIdx],
              customer_communication: { ...appointments[appIdx].customer_communication, ...customer_communication } as CustomerCommunication
            } as Appointment)
          );
        }
      },
      {
        model: "CustomerCommunication",
        action: "upsert",
        filter: { dealer_location_id: Number(selectedLocation?.id) },
        permanent: true,
        callback: message => {
          const appointments = queryClient.getQueryData<Appointment[]>(AppointmentsKeys.list);
          if (!appointments?.length) return;

          const customer_communication = message.data as CustomerCommunication;
          const appIdx = appointments.findIndex(a => a.id === customer_communication.appointment_id);
          if (appIdx < 0) return;

          queryClient.setQueryData(
            AppointmentsKeys.list,
            appointments.with(appIdx, {
              ...appointments[appIdx],
              customer_communication: { ...appointments[appIdx].customer_communication, ...customer_communication } as CustomerCommunication
            } as Appointment)
          );
        }
      },
      {
        model: "Check",
        action: "update",
        permanent: true,
        callback: message => {
          const appointments = queryClient.getQueryData<Appointment[]>(AppointmentsKeys.list);
          if (!appointments?.length) return;

          const check = message.data as Check;
          const appIdx = appointments.findIndex(a => a.id === check.appointment_id);
          if (appIdx < 0) return;

          queryClient.setQueryData(
            AppointmentsKeys.list,
            appointments.with(appIdx, {
              ...appointments[appIdx],
              checklists: [...(appointments[appIdx].checklists ?? []), check.checklist]
            } as Appointment)
          );
        }
      }
    ];
  }, [queryClient, selectedLocation?.id, selectedDate]);

  const fetchAppointments = async ({ queryKey }: QueryFunctionContext<ReadonlyArray<IBackendQueryKey>>): Promise<Appointment[]> => {
    const { baseUrl, endpoint } = queryKey[0];
    const date = moment(selectedDate || new Date()).toISOString();

    const response = await ApiInstance.post(
      endpoint,
      { location_id: selectedLocation?.id, date, list_by_scheduled_range: !!user?.list_appointments_by_scheduled_range },
      baseUrl
    );
    return response?.data?.appointments?.map((app: Appointment) => updateAppointmentStatusIdentifier(app)) || [];
  };

  useEffect(() => {
    if (!selectedLocation?.id || !selectedDate) return;

    if (prevSelectedLocationId !== selectedLocation?.id || prevSelectedDate !== selectedDate) {
      queryClient.invalidateQueries({ queryKey: AppointmentsKeys.list });
    }
  }, [selectedLocation?.id, selectedDate, user?.list_appointments_by_scheduled_range]);

  return useRealTimeQuery({
    queryKey: AppointmentsKeys.list,
    queryFn: fetchAppointments,
    listeners,
    gcTime: Infinity,
    enabled: !!selectedLocation?.id && !!selectedDate && (prevSelectedLocationId !== selectedLocation.id || prevSelectedDate !== selectedDate)
  });
};

export const useRefetchAppointments = () => {
  const queryClient = useQueryClient();

  return {
    refetchAppointments: () => queryClient.invalidateQueries({ queryKey: AppointmentsKeys.list })
  };
};
