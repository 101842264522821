import { createColumnHelper } from "@tanstack/react-table";
import moment from "moment";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Icon, Loader } from "semantic-ui-react";

import { DATE_FORMATS, ReactTable } from "components";
import { LicenseData, LicenseItem, Licenses } from "modules/LocationLicenseOverview/types";
import { combinedLicenseData } from "modules/LocationLicenseOverview/utils";
import { ITranslation } from "util/interfaces";

type OneTimeTableProps = {
  items: LicenseItem[];
  licensesData: Licenses | undefined;
  isLoadingLicenses: boolean;
  onLicenseRowClick: (rowData: LicenseData) => void;
  handleDeleteLicense: (id: number) => void;
};

export const OneTimeTable = ({ licensesData, isLoadingLicenses, items, onLicenseRowClick, handleDeleteLicense }: OneTimeTableProps) => {
  const t = useTranslation().t as ITranslation;

  const oneTimeData = useMemo(() => {
    return combinedLicenseData(licensesData, items, t, false);
  }, [licensesData, items]);

  const columnHelper = createColumnHelper<LicenseData>();
  const columns = [
    columnHelper.accessor(row => row.code, {
      id: "code",
      header: t("v8_code").message || "Code",
      cell: info =>
        info.renderValue() ? (
          <div className="license-code">{info.renderValue()}</div>
        ) : (
          <div className="no-license-code">
            <Icon className="link slash solid red" />
          </div>
        ),
      size: 10
    }),
    columnHelper.accessor(row => row, {
      id: "name",
      header: t("v8_name").message || "Name",
      cell: info => {
        const license = info.renderValue();
        return <div>{license?.description || license?.name || ""}</div>;
      }
    }),
    columnHelper.accessor(row => row.category, {
      id: "category",
      header: t("v8_role_license_service").message || "Role / License / Service",
      cell: info => <div>{info.renderValue()}</div>,
      size: 250
    }),
    columnHelper.accessor(row => row.invoiceOn, {
      id: "invoice_on",
      header: t("v8_invoice_on").message || "Invoice on",
      cell: info => <div>{info.renderValue() ? moment(info.renderValue()).format(DATE_FORMATS.dateMonthYear) : ""}</div>,
      size: 100
    }),
    columnHelper.accessor(row => row.quantity, {
      id: "quantity",
      header: t("v8_amount").message || "Amount",
      cell: info => <div>{info.renderValue()}</div>,
      size: 100
    }),
    columnHelper.accessor(row => row.price, {
      id: "price",
      header: t("v8_unit_price").message || "Unit Price",
      cell: info => <div>{`€ ${info.renderValue()}`}</div>,
      size: 100
    }),
    columnHelper.accessor(row => row.totalPrice, {
      id: "totalPrice",
      header: t("v8_total_price").message || "Total Price",
      cell: info => <div>{`€ ${info.renderValue()}`}</div>,
      size: 100
    }),
    columnHelper.accessor(row => row, {
      id: "actions",
      header: "",
      cell: info => {
        const license = info.renderValue();
        return !license?.categoryID && license?.id ? (
          <div className="subitem-delete">
            <div>
              <Icon
                className="trash red"
                onClick={(e: React.MouseEvent<HTMLDivElement>) => {
                  e.stopPropagation();
                  handleDeleteLicense(license.id);
                }}
              />
            </div>
          </div>
        ) : null;
      },
      size: 50
    })
  ];

  if (isLoadingLicenses) return <Loader active inline size="small" />;

  return (
    <div className="MonthlyTable mb-25">
      <ReactTable
        columns={columns}
        data={oneTimeData || []}
        renderEmptyRow={!oneTimeData?.length}
        emptyRowMessage={t("v8_no_one_time_licenses").message || "No one time licenses"}
        onRowClick={onLicenseRowClick}
      />
    </div>
  );
};
