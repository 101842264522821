import { useTranslation } from "react-i18next";
import { Icon } from "semantic-ui-react";

import { StatusIcon, getAppointmentStatus } from "components";
import { useDealersLocations } from "hooks";
import { CHECK_EVENT_TYPE, COMMUNICATION_EVENT_TYPE, DESK_COMMUNICATION_STATUS, KEYLOCKER_COMMUNICATION_STATUS, STATUS_IDENTIFIER } from "models";
import { ACTIVITY_TYPE_FILTERS } from "modules/AppointmentDetails/components/ActivityLog";
import "modules/AppointmentDetails/components/ActivityLog/components/ActivityType/ActivityType.scss";
import { ITranslation } from "util/interfaces";

type ActivityProps = {
  activityType: ACTIVITY_TYPE_FILTERS;
  statusIdentifier?: STATUS_IDENTIFIER;
  checkEventType?: CHECK_EVENT_TYPE;
  communicationEventType?: COMMUNICATION_EVENT_TYPE;
  communicationStatusType?: DESK_COMMUNICATION_STATUS | KEYLOCKER_COMMUNICATION_STATUS;
};

const CustomerCommunicationIconAndTitle = ({ communicationEventType, t }: { communicationEventType: COMMUNICATION_EVENT_TYPE | undefined; t: ITranslation }) => {
  switch (communicationEventType) {
    case COMMUNICATION_EVENT_TYPE.OnlineCheckInSent:
    case COMMUNICATION_EVENT_TYPE.OnlineCheckInOpened:
    case COMMUNICATION_EVENT_TYPE.OnlineCheckInAnswered:
      return (
        <div>
          <Icon className="envelope activity-step-icon-color" /> {t("v8_online_checkin").message || "Online check-in"}
        </div>
      );

    case COMMUNICATION_EVENT_TYPE.DiagnoseOverviewSent:
    case COMMUNICATION_EVENT_TYPE.DiagnoseOverviewOpened:
    case COMMUNICATION_EVENT_TYPE.DiagnoseOverviewAnswered:
    case COMMUNICATION_EVENT_TYPE.DiagnoseOverviewResent:
      return (
        <div>
          <Icon className="envelope activity-step-icon-color" /> {t("v8_diagnose_overview").message || "Diagnose overview"}
        </div>
      );

    case COMMUNICATION_EVENT_TYPE.RepairOverviewSent:
    case COMMUNICATION_EVENT_TYPE.RepairOverviewResent:
    case COMMUNICATION_EVENT_TYPE.RepairOverviewOpened:
      return (
        <div>
          <Icon className="envelope activity-step-icon-color" /> {t("v8_repair_overview").message || "Repair overview"}
        </div>
      );

    default:
      return (
        <div>
          <Icon className="envelope activity-step-icon-color" /> {t("v8_customer_communication").message || "Customer communication"}
        </div>
      );
  }
};

const CheckEventIconAndTitle = ({ checkEventType, t }: { checkEventType: CHECK_EVENT_TYPE | undefined; t: ITranslation }) => {
  switch (checkEventType) {
    case CHECK_EVENT_TYPE.Started:
      return (
        <div>
          <Icon className="circle quarter stroke activity-step-icon-color" /> {t("v8_checklist_started").message || "Checklist started"}
        </div>
      );

    case CHECK_EVENT_TYPE.Resumed:
      return (
        <div>
          <Icon className="circle quarter stroke activity-step-icon-color" /> {t("v8_checklist_resumed").message || "Checklist resumed"}
        </div>
      );

    case CHECK_EVENT_TYPE.Paused:
      return (
        <div>
          <Icon className="pause activity-step-icon-color" /> {t("v8_checklist_resumed").message || "Checklist paused"}
        </div>
      );

    case CHECK_EVENT_TYPE.Aborted:
      return (
        <div>
          <Icon className="xmark activity-step-icon-color" /> {t("v8_checklist_aborted").message || "Checklist aborted"}
        </div>
      );

    case CHECK_EVENT_TYPE.Completed:
      return (
        <div>
          <Icon className="wrench activity-step-icon-color" /> {t("v8_checklist_completed").message || "Checklist completed"}
        </div>
      );

    default:
      return null;
  }
};

const DeskCommunicationIconAndTitle = ({ t }: { t: ITranslation }) => {
  return (
    <div>
      <Icon className="tablet activity-step-icon-color" /> {t("v8_desk_checkin").message || "Desk check-in"}
    </div>
  );
};

const KeylockerCommunicationIconAndTitle = ({ t }: { t: ITranslation }) => {
  return (
    <div>
      <Icon className="key activity-step-icon-color" /> {t("v8_keylocker_communication").message || "Keylocker communication"}
    </div>
  );
};

const KioskCommunicationIconAndTitle = ({ t }: { t: ITranslation }) => {
  return (
    <div>
      <Icon className="tag activity-step-icon-color" /> {t("v8_kiosk_communication").message || "Keylocker communication"}
    </div>
  );
};

const AcsesReceiverCommunicationIconAndTitle = ({ t }: { t: ITranslation }) => {
  return (
    <div>
      <Icon className="user activity-step-icon-color" /> {t("v8_acses_receiver_communication").message || "Acses receiver communication"}
    </div>
  );
};

export const ActivityType = ({ activityType, checkEventType, communicationEventType, statusIdentifier }: ActivityProps) => {
  const t = useTranslation().t as ITranslation;
  const { selectedLocation } = useDealersLocations();
  const status = getAppointmentStatus(statusIdentifier, selectedLocation?.statuses);

  switch (activityType) {
    case ACTIVITY_TYPE_FILTERS.Appointment:
      return (
        <div>
          <StatusIcon status={status.identifier} className="activity-step-icon-color" /> {status?.name}
        </div>
      );

    case ACTIVITY_TYPE_FILTERS.CheckEvent:
      return <CheckEventIconAndTitle checkEventType={checkEventType} t={t} />;

    case ACTIVITY_TYPE_FILTERS.Customer:
      return <CustomerCommunicationIconAndTitle communicationEventType={communicationEventType} t={t} />;

    case ACTIVITY_TYPE_FILTERS.Desk:
      return <DeskCommunicationIconAndTitle t={t} />;

    case ACTIVITY_TYPE_FILTERS.Keylocker:
      return <KeylockerCommunicationIconAndTitle t={t} />;

    case ACTIVITY_TYPE_FILTERS.Kiosk:
      return <KioskCommunicationIconAndTitle t={t} />;

    case ACTIVITY_TYPE_FILTERS.AcsesReceiver:
      return <AcsesReceiverCommunicationIconAndTitle t={t} />;

    default:
      return null;
  }
};
