import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { DATE_FORMATS, NavPortal } from "components";
import { LicenseItem, Licenses } from "modules/LocationLicenseOverview/types";
import { calculateTotalCosts, getNextMonthlyInvoicingDate } from "modules/LocationLicenseOverview/utils";
import { ITranslation } from "util/interfaces";

type DealerLicenseNavbarProps = {
  items: LicenseItem[];
  licensesData: Licenses | undefined;
  isDealerLevelInvoicingEnabled: boolean;
};

export const DealerLicenseNavbar = ({ items, licensesData, isDealerLevelInvoicingEnabled }: DealerLicenseNavbarProps) => {
  const t = useTranslation().t as ITranslation;

  const { totalOneTimeCosts, totalMonthlyCosts } = useMemo(
    () => ({
      totalOneTimeCosts: calculateTotalCosts(items, licensesData?.oneTimeCategories, licensesData?.oneTimeStandalones),
      totalMonthlyCosts: calculateTotalCosts(items, licensesData?.monthlyCategories, licensesData?.monthlyStandalones)
    }),
    [licensesData, items]
  );

  return (
    <NavPortal>
      <div className="LocationLicenseOverview-Navbar">
        <div className="LocationLicenseOverview-Navbar-Item">
          <span>{t("v8_total_monthly").message || "Total monthly"}:</span>
          {`€ ${isDealerLevelInvoicingEnabled ? totalMonthlyCosts.toFixed(2) : "0.00"}`}
        </div>
        <div className="LocationLicenseOverview-Navbar-Item">
          <span>{t("v8_total_once").message || "Total once"}:</span>
          {`€ ${isDealerLevelInvoicingEnabled ? totalOneTimeCosts.toFixed(2) : "0.00"}`}
        </div>
        <div className="LocationLicenseOverview-Navbar-Item">
          <span>{t("v8_total_this_month").message || "Total this month"}:</span>
          {`€ ${isDealerLevelInvoicingEnabled ? (totalMonthlyCosts + totalOneTimeCosts).toFixed(2) : "0.00"}`}
        </div>
        <div className="LocationLicenseOverview-Navbar-Item">
          <span>{t("v8_next_monthly_bill").message || "Next monthly bill"}:</span>
          {isDealerLevelInvoicingEnabled ? getNextMonthlyInvoicingDate().format(DATE_FORMATS.dateMonthYear) : ""}
        </div>
      </div>
    </NavPortal>
  );
};
