import moment from "moment";
import { useTranslation } from "react-i18next";
import { Grid, Icon, Popup } from "semantic-ui-react";

import { ActionModalSelector, ActionModalSelectorData, DATE_FORMATS } from "components";
import { Appointment, CHECKIN_COMMUNICATION_RESULT_STATUS, CheckInCommunicationResult, QuestionResult } from "models";
import "modules/AppointmentDetails/components/CustomerActions/components/CheckInOptionalService/CheckInOptionalService.scss";
import { ITranslation } from "util/interfaces";

type CheckInOptionalServiceProps = {
  title: string;
  results: CheckInCommunicationResult[];
  canCreateIntervention: boolean;
  onSelect: (data: ActionModalSelectorData, result: CheckInCommunicationResult) => void;
  appointment: Appointment;
  onToggleHandled: (result: CheckInCommunicationResult) => void;
};

export const CheckInOptionalService = ({ title, results, canCreateIntervention, onSelect, onToggleHandled, appointment }: CheckInOptionalServiceProps) => {
  if (!results.length) return null;

  const t = useTranslation().t as ITranslation;

  const communicationStatus = [CHECKIN_COMMUNICATION_RESULT_STATUS.Success, CHECKIN_COMMUNICATION_RESULT_STATUS.Failed];

  return (
    <div className="CustomerActions-items">
      {results
        .filter(result => result.accepted)
        .sort((a, b) => a.id - b.id)
        .map(result => {
          const notificationEmailItemActive = communicationStatus.includes(result.status);
          const actionModelData = {
            title: result.name,
            raw: result.description,
            solution: "",
            price: result.price,
            appointment_id: appointment.id,
            checkin_result_id: result.id
          };

          const isConvertToInterventionButtonDisabled =
            result.can_convert_to_intervention === false || appointment?.interventions?.some(intervention => intervention.check_in_result_id === result.id);
          const successEmailMessage = `${t("v8_sent_to").message || "Sent to"} ${result.notification_email} ${t("v8_on").message || "on"} ${moment(result.updated_on).format(DATE_FORMATS.dateMonthYearTime)}`;
          const failedMailMessage = `${t("v8_failed_to_send_to").message || "Failed to send to"} ${result.notification_email} ${result.reason}`;

          return (
            <Grid key={result.id} className="actions-container">
              <Grid.Column width={12}>
                <div className={`icon-container skyblue ${!notificationEmailItemActive ? "disabled" : ""}`}>
                  <Popup
                    disabled={!result.notification_email}
                    content={
                      <div className="InfoPopup">
                        <p className="InfoTitle">{title}</p>
                        {result.status === CHECKIN_COMMUNICATION_RESULT_STATUS.Success ? (
                          <p className="InfoDescription">{`${t("v8_sent_to").message || "Sent to"} ${result.notification_email} ${t("v8_at").message || "at"} ${moment(
                            result.updated_on
                          ).format(DATE_FORMATS.time)} `}</p>
                        ) : (
                          <p className="InfoDescription">{result.notification_email}</p>
                        )}
                      </div>
                    }
                    trigger={<Icon className="cart" />}
                  />
                </div>
                <p className="title">{result.name}</p>
              </Grid.Column>
              <Grid.Column width={4}>
                <div className="end-grid">
                  {result.price > 0 ? <p className="title checkin-price">€ {result.price.toFixed(2)}</p> : null}
                  <div className="status-container">
                    {!result.notification_email && (
                      <div className="changeHandleStatus" onClick={() => onToggleHandled(result)}>
                        <Icon className={result.receptionist_handled ? "check circle outline green" : "circle xmark outline red"} />
                      </div>
                    )}{" "}
                    {result.notification_email && (
                      <div className={`${notificationEmailItemActive ? "notificationEmailActive" : ""}`}>
                        <Popup
                          disabled={!notificationEmailItemActive}
                          content={result.status === CHECKIN_COMMUNICATION_RESULT_STATUS.Success ? successEmailMessage : failedMailMessage}
                          trigger={
                            <div className="mailTriggerWrapper">
                              <Icon className={`envelope ${notificationEmailItemActive ? "white" : "grey"}`} size="small" />
                            </div>
                          }
                        />
                      </div>
                    )}
                    {canCreateIntervention && (
                      <ActionModalSelector
                        data={actionModelData as QuestionResult}
                        onSelect={data => onSelect(data, result)}
                        onlyIntervention
                        isConvertToInterventionButtonDisabled={isConvertToInterventionButtonDisabled}
                      />
                    )}
                  </div>
                </div>
              </Grid.Column>
            </Grid>
          );
        })}
    </div>
  );
};
