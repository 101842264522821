import { WebsocketStatus } from "components";
import { PATHS } from "router/paths";
import { ITranslation } from "util/interfaces";

export const carDetailRegex = /\/cardetails\/\d+/;
export const appointmentDetailRegex = /\/appointments\/\d+/;
export const leadsRegex = /leads\/?.*/;

export const routesConfig = () => {
  return [
    {
      path: PATHS.APPOINTMENTS,
      titleKey: "v8_appointment_list",
      defaultMessage: "Appointment List",
      defaultClassname: "AppointmentList-Navbar",
      icon: <WebsocketStatus />
    },
    {
      regex: appointmentDetailRegex,
      titleKey: "v8_appointment_details",
      defaultMessage: "Appointment Details",
      defaultClassname: "AppointmentDetails-Navbar",
      icon: <WebsocketStatus />
    },
    {
      regex: carDetailRegex,
      titleKey: "v8_car_details",
      defaultMessage: "Car Details",
      defaultClassname: "CarDetails-Navbar",
      icon: <WebsocketStatus />
    },
    {
      path: PATHS.DAYPLANNER,
      titleKey: "v8_dayplanner",
      defaultMessage: "",
      defaultClassname: "Dayplanner-Navbar"
    },
    {
      path: PATHS.LEADS_DASHBOARD,
      titleKey: "v8_leads",
      defaultMessage: "Leads",
      defaultClassname: "Leads-Navbar"
    },
    {
      path: PATHS.ACCOUNT,
      titleKey: "v8_user",
      defaultMessage: "User",
      defaultClassname: "AccountSettings-Navbar"
    },
    {
      path: PATHS.STYLE_GUIDE,
      titleKey: "v8_claire_v8",
      defaultMessage: "Claire V8",
      defaultClassname: "Styleguide-Navbar"
    },
    {
      path: PATHS.DEALER_DOCUMENTATION,
      titleKey: "v8_dealer_documentations",
      defaultMessage: "Dealer Documentations",
      defaultClassname: "Documentations-Navbar",
      icon: <WebsocketStatus />
    },
    {
      path: PATHS.EXACT_FAILURES,
      titleKey: "v8_exact_online_failures",
      defaultMessage: "Exact Online Failures"
    },
    {
      path: PATHS.NOTES_MENU,
      titleKey: "v8_notes_menu",
      defaultMessage: "Notes",
      defaultClassname: "Notes-Navbar"
    },
    {
      path: PATHS.LOCATION_LICENSE_OVERVIEW,
      titleKey: "v8_location_licenses",
      defaultMessage: "Location Licenses",
      defaultClassname: "LocationLicense-Navbar"
    },
    {
      path: PATHS.DEALER_LICENSE_OVERVIEW,
      titleKey: "v8_dealer_licenses",
      defaultMessage: "Dealer Licenses",
      defaultClassname: "LocationLicense-Navbar"
    }
  ];
};

export const getRouteProperties = (t: ITranslation, location: any) => {
  for (const { path, regex, titleKey, defaultMessage, defaultClassname, icon } of routesConfig()) {
    if (location.pathname === path || (regex && regex.test(location.pathname))) {
      return {
        title: (
          <>
            {defaultMessage && <h4>{t(titleKey)?.message || defaultMessage}</h4>}
            {icon}
          </>
        ),
        defaultClassname: defaultClassname
      };
    }
  }

  return {
    title: <h4>Claire V8</h4>,
    defaultClassname: "ClaireV8"
  };
};
