import { createColumnHelper } from "@tanstack/react-table";
import moment from "moment";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Icon, Loader } from "semantic-ui-react";

import { DATE_FORMATS, ReactTable } from "components";
import { LicenseData, LicenseItem, Licenses } from "modules/LocationLicenseOverview/types";
import { combinedLicenseData } from "modules/LocationLicenseOverview/utils";
import { ITranslation } from "util/interfaces";

type OnceTableProps = {
  items: LicenseItem[];
  licensesData: Licenses | undefined;
  isLoadingLicenses: boolean;
};

export const OnceTable = ({ licensesData, isLoadingLicenses, items }: OnceTableProps) => {
  const t = useTranslation().t as ITranslation;

  const oneTimeData = useMemo(() => {
    return combinedLicenseData(licensesData, items, t, false);
  }, [licensesData, items]);

  const columnHelper = createColumnHelper<LicenseData>();
  const columns = [
    columnHelper.accessor(row => row.code, {
      id: "code",
      header: t("v8_code").message || "Code",
      cell: info =>
        info.renderValue() ? (
          <div className="license-code">{info.renderValue()}</div>
        ) : (
          <div className="no-license-code">
            <Icon className="link slash solid red" />
          </div>
        ),
      size: 10
    }),
    columnHelper.accessor(row => row, {
      id: "name",
      header: t("v8_name").message || "Name",
      cell: ({ getValue }) => {
        const { name, description } = getValue();
        return <span style={{ cursor: "default" }}>{description || name || ""}</span>;
      }
    }),
    columnHelper.accessor(row => row.category, {
      id: "category",
      header: t("v8_role_license_service").message || "Role / License / Service",
      cell: info => info.renderValue(),
      size: 250
    }),
    columnHelper.accessor(row => row.invoiceOn, {
      id: "invoice_on",
      header: t("v8_invoice_on").message || "Invoice on",
      cell: info => (info.renderValue() ? moment(info.renderValue()).format(DATE_FORMATS.dateMonthYear) : ""),
      size: 100
    }),
    columnHelper.accessor(row => row.quantity, {
      id: "quantity",
      header: t("v8_amount").message || "Amount",
      cell: info => info.renderValue(),
      size: 100
    }),
    columnHelper.accessor(row => row.totalPrice, {
      id: "totalPrice",
      header: t("v8_total_price").message || "Total Price",
      cell: info => `€ ${info.renderValue()}`,
      size: 100
    })
  ];

  if (isLoadingLicenses) return <Loader active inline="centered" size="small" />;

  return (
    <div className="MonthlyTable mb-25">
      <ReactTable
        columns={columns}
        data={oneTimeData}
        renderEmptyRow={!oneTimeData?.length}
        emptyRowMessage={t("v8_no_one_time_licenses").message || "No one time licenses"}
      />
    </div>
  );
};
