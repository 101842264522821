import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Checkbox, Icon } from "semantic-ui-react";

import { DetailsCards, useCan } from "components";
import { useDealersLocations } from "hooks/useDealersLocations";
import { Appointment, Intervention, STATUS_IDENTIFIER } from "models";
import { CustomerCommunicationInterventionItem } from "modules/AppointmentDetails/components/CustomerCommunicationModal/components/CustomerCommunicationInterventions/components";
import "modules/AppointmentDetails/components/CustomerCommunicationModal/components/CustomerCommunicationInterventions/CustomerCommunicationInterventions.scss";
import { useIntervention } from "modules/AppointmentDetails/hooks";
import { getPriceInVAT } from "util/common";
import { ITranslation } from "util/interfaces";

type CustomerCommunicationInterventionsProps = {
  appointment: Appointment;
  invalidInterventions?: boolean;
  showCustomerOk?: boolean;
};

export const CustomerCommunicationInterventions = ({ appointment, invalidInterventions, showCustomerOk }: CustomerCommunicationInterventionsProps) => {
  const [showVat, setShowVat] = useState(true);
  const { selectedLocation: location } = useDealersLocations();
  const canUpdateInterventions = useCan("update", "appointments");
  const { updateIntervention } = useIntervention(appointment.id);
  const t = useTranslation().t as ITranslation;

  const approvedInterventions = appointment?.interventions?.filter(intervention => intervention.customer_ok);
  const pendingInterventions = appointment?.interventions?.filter(intervention => !intervention.customer_ok);
  const totalAmountOfInterventions = useMemo(() => {
    if (!appointment) return 0;

    const eligibleInterventions = appointment.interventions?.filter(intervention => intervention.visible_to_customer) || [];
    const sum = eligibleInterventions.reduce((sum: number, intervention: Intervention) => sum + intervention.price, 0) || 0;
    return showVat ? getPriceInVAT(sum, location).toFixed(2) : sum.toFixed(2);
  }, [appointment, location, showVat]);

  const totalCustomerOkPrice = useMemo(() => {
    if (!appointment) return 0;

    const eligibleInterventions = appointment.interventions?.filter(intervention => intervention.customer_ok && intervention.visible_to_customer) || [];
    const sum = eligibleInterventions?.reduce((sum, intervention) => sum + intervention.price, 0) || 0;

    return showVat ? getPriceInVAT(sum, location).toFixed(2) : sum.toFixed(2);
  }, [appointment, location, showVat]);

  const toggleVisibleInPDF = (item: Intervention) => {
    if (!canUpdateInterventions) return;

    if (appointment?.appointment_status_identifier !== STATUS_IDENTIFIER.CanceledStatus) {
      updateIntervention.mutate({
        ...item,
        visible_to_customer: !item.visible_to_customer
      });
    }
  };

  const toggleCustomerOk = (item: Intervention) => {
    if (!showCustomerOk) return;

    if (appointment?.appointment_status_identifier !== STATUS_IDENTIFIER.CanceledStatus) {
      updateIntervention.mutate({
        ...item,
        customer_ok: !item.customer_ok
      });
    }
  };

  return (
    <>
      <DetailsCards
        title={`${t("interventions").message || "Interventions"} ${appointment?.interventions?.length ? `(${appointment.interventions.length})` : ""}`}
        icon="screwdriver wrench"
        isExpanded={true}
        showExpandArrow={false}
        containerStyle={invalidInterventions ? "error" : ""}
        rightContent={
          <div className="full end">
            <p>{t("v8_show_prices_with_vat").message || "Show prices with VAT"}</p>
            <Checkbox toggle checked={showVat} onChange={() => setShowVat(prev => !prev)} />
          </div>
        }
      >
        <div className="CustomerCommunicationInterventions">
          <div className="CustomerCommunicationInterventions-container">
            {!!pendingInterventions?.length && (
              <div className="CustomerCommunicationInterventions-section">
                <div className="CustomerCommunicationInterventions-section-group">
                  <div className="CustomerCommunicationInterventions-section-group_header">
                    <Icon className="crossIcon circle xmark outline red" />
                    <p>{t("v8_approval_pending").message || "Approval pending"}</p>
                  </div>
                  {pendingInterventions?.map((item: Intervention) => {
                    return (
                      <CustomerCommunicationInterventionItem
                        key={item.id}
                        intervention={item}
                        toggleVisibleInPDF={toggleVisibleInPDF}
                        showVat={showVat}
                        {...(showCustomerOk && { toggleCustomerOk })}
                      />
                    );
                  })}
                </div>
              </div>
            )}

            {!!approvedInterventions?.length && (
              <div className="CustomerCommunicationInterventions-section">
                <div className="CustomerCommunicationInterventions-section-group">
                  <div className="CustomerCommunicationInterventions-section-group_header">
                    <Icon className="check circle outline green" />
                    <p>{t("v8_approved").message || "Approved"}</p>
                  </div>
                  {approvedInterventions?.map((item: Intervention) => {
                    return (
                      <CustomerCommunicationInterventionItem
                        key={item.id}
                        intervention={item}
                        toggleVisibleInPDF={toggleVisibleInPDF}
                        showVat={showVat}
                        {...(showCustomerOk && { toggleCustomerOk })}
                      />
                    );
                  })}
                </div>
              </div>
            )}
          </div>

          <div className="total-amount-container">
            <div className="float-right">
              <p>
                {t("v8_total_amount_of_selected_items").message || "Total amount of selected items"}: <strong>€ {totalAmountOfInterventions}</strong>
              </p>
            </div>
            {showCustomerOk && (
              <div className="float-right">
                <p>
                  {t("v8_customer_approved_total").message || "Customer approved total"}: <strong>€ {totalCustomerOkPrice}</strong>
                </p>
              </div>
            )}
          </div>
        </div>
      </DetailsCards>
    </>
  );
};
