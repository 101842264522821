import { Icon, Label } from "semantic-ui-react";

import "components/PinUserLabel/PinUserLabel.scss";
import { PinModel, ROLES } from "models";

type PinUserLabelProps = {
  currentPin: PinModel;
};

export const PinUserLabel = ({ currentPin }: PinUserLabelProps) => {
  const getCurrentUserIcon = (isDms: boolean | undefined, userRole: number | undefined) => {
    if (isDms) return <Icon className="desktop dms-bg-color" />;

    if (userRole === ROLES.Mechanic) return <Icon className="wrench mechanic-color" />;
    if (userRole === ROLES.WarrantyManager) return <Icon className="shield halved" />;
    if (userRole === ROLES.ChiefMechanic)
      return (
        <Icon.Group>
          <Icon className="wrench" />
          <Icon corner="bottom right" className="sparkle" />
        </Icon.Group>
      );

    return <Icon className="pen default-colors" />;
  };

  const getUserColors = (userRole: number | undefined) => {
    if (userRole === ROLES.Mechanic) return "mechanic-color mechanic-bg-color";
    if (userRole === ROLES.WarrantyManager) return "warranty-color warranty-bg-colors";
    if (userRole === ROLES.ChiefMechanic) return "chief-mechanic-color";

    return "default-colors";
  };
  return (
    <Label className={getUserColors(currentPin.user?.role_id)}>
      {getCurrentUserIcon(currentPin.is_dms, currentPin.user?.role_id)} {currentPin.is_dms ? "DMS " : `${currentPin.user?.first_name} ${currentPin.user?.last_name}`}
    </Label>
  );
};
