import { QueryFunctionContext, useMutation, useQueryClient } from "@tanstack/react-query";
import { useMemo } from "react";

import { WebSocketMessageListener } from "components";
import ENV from "config/Env";
import { useDealersLocations, useRealTimeQuery } from "hooks";
import { ExactCategory, ExactStandaloneLicense } from "models";
import { LocationLicenseOverviewKeys } from "modules/LocationLicenseOverview/queryKeys";
import { Licenses, StandaloneLicenceDeletionMutationArgs } from "modules/LocationLicenseOverview/types";
import { transformData, updateCategoryArray, updateLicenseArray, updateLicenseData } from "modules/LocationLicenseOverview/utils";
import ApiInstance from "util/Api";
import { IBackendQueryKey } from "util/keyFactory";

export const useLicenses = () => {
  const { selectedLocation } = useDealersLocations();
  const queryClient = useQueryClient();

  const listeners = useMemo((): WebSocketMessageListener[] => {
    if (!selectedLocation?.id) return [];

    return [
      {
        model: "StandaloneLicense",
        action: "create",
        callback: message => {
          updateLicenseData(
            licenses => {
              const license = message.data as ExactStandaloneLicense;
              const standaloneLicenses = [...licenses.monthlyStandalones, ...licenses.oneTimeStandalones];
              if (standaloneLicenses.some(lc => lc.id === license.id)) return licenses;

              return {
                ...licenses,
                monthlyStandalones: !license.invoice_on ? [...(licenses.monthlyStandalones ?? []), license] : licenses.monthlyStandalones,
                oneTimeStandalones: license.invoice_on ? [...(licenses.oneTimeStandalones ?? []), license] : licenses.oneTimeStandalones
              } as Licenses;
            },
            selectedLocation.id,
            queryClient
          );
        }
      },
      {
        model: "StandaloneLicense",
        action: "update",
        callback: message => {
          updateLicenseData(
            licenses => {
              const license = message.data as ExactStandaloneLicense;
              return {
                ...licenses,
                monthlyStandalones: updateLicenseArray(licenses.monthlyStandalones, license, !license.invoice_on),
                oneTimeStandalones: updateLicenseArray(licenses.oneTimeStandalones, license, !!license.invoice_on)
              } as Licenses;
            },
            selectedLocation.id,
            queryClient
          );
        }
      },
      {
        model: "StandaloneLicense",
        action: "delete",
        callback: message => {
          updateLicenseData(
            licenses => {
              const license = message.data as ExactStandaloneLicense;
              const standaloneLicenses = [...licenses.monthlyStandalones, ...licenses.oneTimeStandalones];

              if (!standaloneLicenses.some(lc => lc.id === license.id)) return licenses;
              return {
                ...licenses,
                monthlyStandalones: licenses.monthlyStandalones.filter(lc => lc.id !== license.id),
                oneTimeStandalones: licenses.oneTimeStandalones.filter(lc => lc.id !== license.id)
              } as Licenses;
            },
            selectedLocation.id,
            queryClient
          );
        }
      },
      {
        model: "CategoryLicense",
        action: "update",
        callback: message => {
          updateLicenseData(
            licenses => {
              const category = message.data as ExactCategory;
              return {
                ...licenses,
                monthlyCategories: updateCategoryArray(licenses.monthlyCategories, category, !category.one_time),
                oneTimeCategories: updateCategoryArray(licenses.oneTimeCategories, category, !!category.one_time)
              } as Licenses;
            },
            selectedLocation.id,
            queryClient
          );
        }
      }
    ];
  }, [queryClient, selectedLocation?.id]);

  const getLicenses = async ({ queryKey }: QueryFunctionContext<ReadonlyArray<IBackendQueryKey>>) => {
    if (!selectedLocation?.id) throw new Error("invalid location");

    const { baseUrl, endpoint, params } = queryKey[0];
    const res = await ApiInstance.post(endpoint, params, baseUrl);
    const data = transformData(res.data);
    return data;
  };

  const refreshLicenses = () => {
    queryClient.invalidateQueries({ queryKey: LocationLicenseOverviewKeys.licenses({ dealer_location_id: selectedLocation?.id }) });
  };

  const realTimeQuery = useRealTimeQuery({
    queryKey: LocationLicenseOverviewKeys.licenses({ dealer_location_id: selectedLocation?.id }),
    queryFn: getLicenses,
    listeners,
    gcTime: Infinity,
    retry: false,
    enabled: !!selectedLocation?.id
  });

  return {
    data: realTimeQuery.data,
    isLoadingLicenses: realTimeQuery.isFetching,
    error: realTimeQuery.error,
    refreshLicenses
  };
};

export const useSelectedStandaloneLicenceDelete = () => {
  const queryClient = useQueryClient();
  const { selectedLocation } = useDealersLocations();

  return useMutation({
    mutationFn: async (params: StandaloneLicenceDeletionMutationArgs) => {
      const response = await ApiInstance.post(`/licenses/standalone/delete`, params, ENV.exactBaseUrl);
      return response.data;
    },
    onMutate: params => {
      const { standalone_license_id } = params;
      if (!selectedLocation?.id) throw new Error("invalid location");
      const licenses = updateLicenseData(
        licenses => {
          const standaloneLicenses = [...licenses.monthlyStandalones, ...licenses.oneTimeStandalones];
          if (!standaloneLicenses.some(license => license.id === standalone_license_id)) return licenses;
          return {
            ...licenses,
            monthlyStandalones: licenses.monthlyStandalones.filter(license => license.id !== standalone_license_id),
            oneTimeStandalones: licenses.oneTimeStandalones.filter(license => license.id !== standalone_license_id)
          } as Licenses;
        },
        selectedLocation.id,
        queryClient
      );
      return { licenses };
    },
    onError: (_error, _variables, context) => {
      if (selectedLocation?.id) queryClient.setQueryData(LocationLicenseOverviewKeys.licenses({ dealer_location_id: selectedLocation.id }), context?.licenses);
    }
  });
};

export const useSelectedStandaloneLicenceCreate = () => {
  const queryClient = useQueryClient();
  const { selectedLocation } = useDealersLocations();

  return useMutation({
    mutationFn: async (params: Record<string, Partial<ExactStandaloneLicense>>) => {
      const response = await ApiInstance.post(`/licenses/standalone/create`, params, ENV.exactBaseUrl);
      return response.data.exact_standalone_license_id;
    },
    onMutate: () => {
      if (!selectedLocation?.id) throw new Error("invalid location");
      const queryKey = LocationLicenseOverviewKeys.licenses({ dealer_location_id: selectedLocation.id });
      const licenses = queryClient.getQueryData<Licenses>(queryKey);
      return { licenses };
    },
    onSuccess: (id: number, variables) => {
      if (!selectedLocation?.id) throw new Error("invalid location");
      updateLicenseData(
        licenses => {
          const license = { id, ...variables.license } as ExactStandaloneLicense;
          const standaloneLicenses = [...licenses.monthlyStandalones, ...licenses.oneTimeStandalones];
          if (standaloneLicenses.some(license => license.id === id)) return licenses;
          return {
            ...licenses,
            monthlyStandalones: !license.invoice_on ? [...(licenses.monthlyStandalones ?? []), license] : licenses.monthlyStandalones,
            oneTimeStandalones: license.invoice_on ? [...(licenses.oneTimeStandalones ?? []), license] : licenses.oneTimeStandalones
          } as Licenses;
        },
        selectedLocation.id,
        queryClient
      );
    },
    onError: (_error, _variables, context) => {
      if (selectedLocation?.id) queryClient.setQueryData(LocationLicenseOverviewKeys.licenses({ dealer_location_id: selectedLocation.id }), context?.licenses);
    }
  });
};

export const useSelectedStandaloneLicenceUpdate = () => {
  const queryClient = useQueryClient();
  const { selectedLocation } = useDealersLocations();

  return useMutation({
    mutationFn: async (params: Record<string, ExactStandaloneLicense>) => {
      const response = await ApiInstance.post(`/licenses/standalone/update`, params, ENV.exactBaseUrl);
      return response.data;
    },
    onMutate: params => {
      if (!selectedLocation?.id) throw new Error("invalid location");
      const licenses = updateLicenseData(
        licenses => {
          return {
            ...licenses,
            monthlyStandalones: updateLicenseArray(licenses.monthlyStandalones, params.license, !params.license.invoice_on),
            oneTimeStandalones: updateLicenseArray(licenses.oneTimeStandalones, params.license, !!params.license.invoice_on)
          } as Licenses;
        },
        selectedLocation.id,
        queryClient
      );
      return { licenses };
    },
    onError: (_error, _variables, context) => {
      if (selectedLocation?.id) queryClient.setQueryData(LocationLicenseOverviewKeys.licenses({ dealer_location_id: selectedLocation.id }), context?.licenses);
    }
  });
};

export const useSelectedCategoryLicenseUpdate = () => {
  const queryClient = useQueryClient();
  const { selectedLocation } = useDealersLocations();

  return useMutation({
    mutationFn: async (params: Record<string, ExactCategory>) => {
      const response = await ApiInstance.post(`/licenses/category/update`, params, ENV.exactBaseUrl);
      return response.data;
    },
    onMutate: params => {
      if (!selectedLocation?.id) throw new Error("invalid location");
      const licenses = updateLicenseData(
        licenses => {
          return {
            ...licenses,
            monthlyCategories: updateCategoryArray(licenses.monthlyCategories, params.category, !params.category.one_time),
            oneTimeCategories: updateCategoryArray(licenses.oneTimeCategories, params.category, !!params.category.one_time)
          } as Licenses;
        },
        selectedLocation.id,
        queryClient
      );
      return { licenses };
    },
    onError: (_error, _variables, context) => {
      if (selectedLocation?.id) queryClient.setQueryData(LocationLicenseOverviewKeys.licenses({ dealer_location_id: selectedLocation.id }), context?.licenses);
    }
  });
};
