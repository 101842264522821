import moment from "moment";
import React, { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, ButtonGroup, Dropdown, Form, Icon, Input, TextArea } from "semantic-ui-react";

import { CUSTOM_CONFIRM_TYPES, Can, CustomConfirm, PinTypeIcon, useCan } from "components";
import { useUser } from "hooks";
import { Appointment, Intervention, PIN_TYPE, PinModel, STATUS_IDENTIFIER } from "models";
import { PinType } from "modules/AppointmentDetails/components";
import "modules/AppointmentDetails/components/Interventions/components/GeneralInfo/GeneralInfo.scss";
import { InterventionData, useIntervention } from "modules/AppointmentDetails/hooks";
import { useConnectedInterventionId } from "modules/AppointmentDetails/hooks/index";
import { ITranslation } from "util/interfaces";

type GeneralInfoProps = {
  data: Intervention;
  appointment: Appointment | null;
  onPinTypeSet: (pinTypeID: PIN_TYPE) => void;
  pinTypeData: PinType[];
  typeID: number;
  onSetHasChanges?: (change: boolean) => void;
};

export const GeneralInfo = ({ data, appointment, onPinTypeSet, typeID, pinTypeData, onSetHasChanges }: GeneralInfoProps) => {
  const [customerOk, setCustomerOk] = useState(data.customer_ok || false);
  const [disabled, setDisabled] = useState(true);
  const [pinTypeID, setPinTypeID] = useState<PIN_TYPE>(typeID);
  const [openDropdown, setOpenDropdown] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [showDeleteInterventionModal, setShowDeleteInterventionModal] = useState(false);
  const [deletePinReason, setDeletePinReason] = useState("");

  const savedPinTypeID = useRef(typeID);
  const tempFormEvent = useRef<React.FormEvent<HTMLFormElement>>();
  const t = useTranslation().t as ITranslation;

  const toggleCustomerOk = () => {
    setCustomerOk(prev => !prev);
    setDisabled(false);
    onSetHasChanges?.(true);
  };
  const { updateIntervention, addPinItem, deletePinItem, deleteIntervention } = useIntervention(data?.appointment_id);
  const user = useUser();
  const canUpdateInterventions = useCan("update", "interventions");
  const { updateConnectedInterventionId } = useConnectedInterventionId();

  const handlePinType = (pinTypeID: PIN_TYPE) => {
    setPinTypeID(pinTypeID);
    onPinTypeSet(pinTypeID);

    setDisabled(false);
    onSetHasChanges?.(true);
  };

  const handleConfirm = async () => {
    setShowConfirmModal(false);

    const formData: any = Object.fromEntries(new FormData((tempFormEvent.current as any).target));
    const intervention: InterventionData = {
      ...formData,
      price: Number(formData.price),
      customer_ok: customerOk,
      appointment_id: data.appointment_id,
      dms_nr: data.dms_nr,
      id: data.id,
      visible_to_customer: data.visible_to_customer
    };
    await updateIntervention.mutateAsync(intervention);

    if (pinTypeID === PIN_TYPE.NotSet && savedPinTypeID.current) {
      await deletePinItem.mutateAsync({ intervention_id: data.id, note: deletePinReason });
      savedPinTypeID.current = pinTypeID;
    }
    if (pinTypeID) {
      const pin: PinModel = {
        pin_type_id: pinTypeID,
        order_status: null,
        intervention_id: data.id,
        appointment: null,
        appointment_id: data.appointment_id,
        intervention: null,
        user_id: user ? user.id : 0,
        question_result: null,
        note_attachments: [],
        created_on: moment().utc().format("YYYY-MM-DDTHH:mm:ssZ")
      };
      await addPinItem.mutateAsync(pin);
    }

    savedPinTypeID.current = pinTypeID;
    setDisabled(true);
    onSetHasChanges?.(false);
  };

  const handleUpdateIntervention = async (e: React.FormEvent<HTMLFormElement>) => {
    if (!data) return;

    tempFormEvent.current = e;

    if (savedPinTypeID.current !== PIN_TYPE.NotSet && pinTypeID === PIN_TYPE.NotSet) {
      setShowConfirmModal(true);
      return;
    }
    handleConfirm();
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value != data[e.target.name as keyof Intervention]) {
      setDisabled(false);
      onSetHasChanges?.(true);
    } else {
      setDisabled(true);
      onSetHasChanges?.(false);
    }
  };

  const onRequestCloseModal = () => setShowConfirmModal(false);
  const toggleDropdown = () => setOpenDropdown(prev => !prev);

  const handleDeleteLocalInterventionConfirm = () => {
    deleteIntervention.mutate(data.id);
    setShowDeleteInterventionModal(false);
    updateConnectedInterventionId(null);
  };
  const handleCancelDeleteLocalIntervention = () => setShowDeleteInterventionModal(false);
  const handleDeleteLocalIntervention = () => setShowDeleteInterventionModal(true);

  const isDisabled = appointment?.appointment_status_identifier === STATUS_IDENTIFIER.CanceledStatus || !canUpdateInterventions;

  return (
    <div className="GeneralInfo">
      <Form onSubmit={handleUpdateIntervention} onChange={handleChange}>
        <Form.Group className="input-row-equal-spacing">
          <Form.Field width={16} required>
            <label>{t("v8_title").message || "Title"}</label>
            <Input defaultValue={data.title} placeholder="Title" fluid name="title" disabled={isDisabled} />
          </Form.Field>

          <Form.Field width={2} className="mt-28">
            <Input
              defaultValue={data.price}
              name="price"
              label={{ basic: true, content: "€" }}
              labelPosition="right"
              disabled={data.is_keyloop || !canUpdateInterventions}
            />
          </Form.Field>

          <Form.Field className="mt-28 label-dropdown-container">
            <ButtonGroup
              className="withdropdown"
              onClick={(e: React.FormEvent<HTMLFormElement>) => {
                e.stopPropagation();
                e.preventDefault();
                toggleDropdown();
              }}
            >
              <Button className={`withdropdownButton ${pinTypeID === 0 ? "grey" : "black"}`} disabled={isDisabled}>
                <PinTypeIcon pin_type_id={pinTypeID || 0} />
              </Button>
              <Dropdown className="button icon" floating open={openDropdown} onOpen={toggleDropdown} onClose={toggleDropdown} disabled={isDisabled}>
                <Dropdown.Menu>
                  <Dropdown.Item onClick={() => handlePinType(0)} className="no-label" disabled={isDisabled}>
                    <Icon name="pin" />
                    {"No Label"}
                  </Dropdown.Item>
                  {pinTypeData?.map((pinType: PinType) => (
                    <Dropdown.Item
                      key={pinType.key}
                      onClick={() => handlePinType(pinType.value as PIN_TYPE)}
                      disabled={isDisabled}
                      icon={pinType.icon}
                      text={pinType.text}
                    />
                  ))}
                </Dropdown.Menu>
              </Dropdown>
            </ButtonGroup>
          </Form.Field>

          <Form.Field width={1} className="mt-28 user-approved-icon">
            <Button disabled={isDisabled} onClick={toggleCustomerOk} className="wdLightGrey centerIcon greyBorder" type="button">
              <Icon className={`${customerOk ? "check circle green" : "remove circle red"}`} />
            </Button>
          </Form.Field>
        </Form.Group>

        <Form.Field width={16} className="mt-14">
          <label>{t("v8_description").message || "Description"}</label>
          <TextArea disabled={isDisabled} defaultValue={data.description} placeholder="Write description" fluid name="description" />
        </Form.Field>

        <div className={data.is_local ? "flex-space-between" : "flex-end"}>
          {data.is_local && (
            <Can I="delete" the="interventions">
              <Button color="red" type="button" onClick={handleDeleteLocalIntervention}>
                {t("v8_delete").message || "Delete"}
                <Icon className="trash" />
              </Button>
            </Can>
          )}
          <Can I="update" the="interventions">
            <Button color="green" type="submit" disabled={disabled || !canUpdateInterventions}>
              {t("v8_save").message || "Save"}
              <Icon className="check" />
            </Button>
          </Can>
        </div>
      </Form>

      <CustomConfirm
        isLoading={false}
        type={CUSTOM_CONFIRM_TYPES.Danger}
        isOpen={showConfirmModal}
        handleConfirm={handleConfirm}
        handleCancel={onRequestCloseModal}
        confirmMandatoryNote={t("v8_reason_for_delete_pin").message || "Reason for deleting pin item"}
        onChangeNote={e => setDeletePinReason(e.target.value)}
        confirmMsg={t("v8_unsaved_changes_confirm_message").message || "You have some unsaved changes. If you proceed, these changes will be lost."}
      />
      <CustomConfirm
        isLoading={deleteIntervention.isPending}
        isOpen={showDeleteInterventionModal}
        type={CUSTOM_CONFIRM_TYPES.Danger}
        handleConfirm={handleDeleteLocalInterventionConfirm}
        handleCancel={handleCancelDeleteLocalIntervention}
        confirmMsg={t("v8_delete_confirm_message").message || "Are you sure that you want to delete this? You can't undo this action?"}
      />
    </div>
  );
};
