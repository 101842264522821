import { Ref, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { GridColumn, GridRow, Icon, Popup } from "semantic-ui-react";

import { ActionModalSelectorData, CUSTOM_CONFIRM_TYPES, CustomConfirm, Editable, Images, Videos, useCan } from "components";
import { useDealersLocations, useHover, useTyreName, useUser } from "hooks";
import { Appointment, ChecklistTemplate, QuestionResult, QuestionResultImage, QuestionResultVideo, ROLES, STATUS_IDENTIFIER, Tyre } from "models";
import { TyreReplacementTableItem } from "modules/AppointmentDetails/components/Tyre/components";
import "modules/AppointmentDetails/components/Tyre/components/TyreTableItem/TyreTableItem.scss";
import useTyre from "modules/AppointmentDetails/components/Tyre/hooks/useTyre";
import TyreActions from "modules/AppointmentDetails/components/Tyre/TyreActions";
import { getTyreDescription, size } from "modules/AppointmentDetails/components/Tyre/TyreTable";
import { useChecklist } from "modules/AppointmentDetails/hooks";
import { getTyreSeasonIcon, isCarReadyOrQualityCheckDoneOrCanceled } from "util/common";
import { ITranslation } from "util/interfaces";

type TyreTableItemProps = {
  data: QuestionResult;
  template?: ChecklistTemplate | null;
  onRequestReplacement?: (data: QuestionResult) => void;
  onSelectAction: (data: ActionModalSelectorData) => void;
  appointment: Appointment;
};

export const TyreTableItem = ({ data, template, appointment, onSelectAction, onRequestReplacement }: TyreTableItemProps) => {
  const t = useTranslation().t as ITranslation;
  const user = useUser();
  const [showMedia, setShowMedia] = useState(false);
  const [selectedDeleteMedia, setSelectedDeleteMedia] = useState<{ data: QuestionResultImage | QuestionResultVideo; index: number } | null>(null);

  const questionRef: Ref<HTMLParagraphElement> | undefined = useRef(null);
  const sizeRef: Ref<HTMLParagraphElement> | undefined = useRef(null);
  const typeRef: Ref<HTMLParagraphElement> | undefined = useRef(null);
  const brandRef: Ref<HTMLParagraphElement> | undefined = useRef(null);

  const { canHover: canQuestionHover } = useHover(questionRef);
  const { canHover: canSizeHover } = useHover(sizeRef);
  const { canHover: canTypeHover } = useHover(typeRef);
  const { canHover: canBrandHover } = useHover(brandRef);

  const { getTyreName } = useTyreName();
  const { selectedLocation } = useDealersLocations();
  const { tyreRemarkMutation } = useTyre(data.appointment_id);
  const { updateCheckQuestionItem } = useChecklist(data.appointment_id);
  const canUpdateTyreTableItem = useCan("update", "appointments");

  const handleDeleteImage = (image: QuestionResultImage, index: number) => {
    const updatedImage = { ...image, active: false };
    const updatedData = { ...data };
    (updatedData as QuestionResult).images[index] = updatedImage;
    updateCheckQuestionItem.mutate({ data: updatedData as QuestionResult });
  };

  const updateVideoInQuestionItem = (updatedVideo: QuestionResultVideo, index: number) => {
    const updatedData = { ...data };
    (updatedData as QuestionResult).videos[index] = updatedVideo;
    updateCheckQuestionItem.mutate({ data: updatedData as QuestionResult });
  };

  const handleDeleteVideo = (video: QuestionResultVideo, index: number) => {
    updateVideoInQuestionItem({ ...video, active: false }, index);
  };

  const handleVideoUpload = (video: QuestionResultVideo, index: number, url: string) => {
    updateVideoInQuestionItem({ ...video, url: url }, index);
  };

  const handleVideoVisibility = (video: QuestionResultVideo, index: number) => {
    updateVideoInQuestionItem({ ...video, visible: !video.visible }, index);
  };

  const handleDeleteMedia = () => {
    if (!selectedDeleteMedia) return;
    const isImage = "visible_in_pdf" in selectedDeleteMedia.data;
    if (isImage) handleDeleteImage(selectedDeleteMedia.data as QuestionResultImage, selectedDeleteMedia.index);
    else handleDeleteVideo(selectedDeleteMedia.data as QuestionResultVideo, selectedDeleteMedia.index);
    setSelectedDeleteMedia(null);
  };

  const isEditable = () => {
    if (selectedLocation?.is_tyre_team_enabled) {
      return true;
    }

    return canUpdateTyreTableItem && !isCarReadyOrQualityCheckDoneOrCanceled(appointment);
  };

  const handleRemark = (text: string) => {
    tyreRemarkMutation.mutate({ ...data, raw: text } as QuestionResult);
  };

  const toggleShowMedia = () => {
    setShowMedia(prev => !prev);
  };

  const images = data?.images?.filter(item => item.active) || [];
  const videos = data?.videos?.filter(item => item.active) || [];

  const tyreSeasonIcon = getTyreSeasonIcon(Number(data.tyre?.season));
  const isRoleAllowed = user ? ![ROLES.Supervisor, ROLES.Mechanic, ROLES.Manufacturer].includes(user?.role_id) : true;

  return (
    <>
      <GridRow className="TyreTableItem">
        <GridColumn width={2}>
          <div className="tyre-position-container">
            {onRequestReplacement && isRoleAllowed && !isCarReadyOrQualityCheckDoneOrCanceled(appointment) && (
              <div className="tyre-replacement-icon-container">
                <Icon className="plus circle" color="blue" size="tiny" onClick={() => onRequestReplacement(data)} />
              </div>
            )}
            <Popup
              hoverable
              disabled={canQuestionHover}
              content={getTyreName(data.tyre_position)}
              trigger={<span ref={questionRef}>{getTyreName(data.tyre_position)}</span>}
            />
          </div>
        </GridColumn>
        <GridColumn width={2}>
          <Popup
            hoverable
            disabled={canSizeHover}
            content={size(data.tyre as Tyre)}
            trigger={
              <div className="tyre-season-container">
                {tyreSeasonIcon && <Icon className={tyreSeasonIcon} />}
                <p ref={sizeRef}>{size(data.tyre as Tyre)}</p>
              </div>
            }
          />
        </GridColumn>
        <GridColumn width={4}>
          <Popup
            hoverable
            disabled={canTypeHover}
            content={getTyreDescription(data.tyre as Tyre)}
            trigger={<p ref={typeRef}>{getTyreDescription(data.tyre as Tyre)}</p>}
          />
        </GridColumn>
        <GridColumn width={1}>
          <Popup hoverable disabled={canBrandHover} content={data.tyre?.manufacturer} trigger={<p ref={brandRef}>{data.tyre?.manufacturer}</p>} />
        </GridColumn>
        <GridColumn width={1}>{data.tyre_profile ? `${data.tyre_profile} mm` : ""}</GridColumn>
        <GridColumn width={1}>{data.price || ""}</GridColumn>

        <GridColumn width={2}>
          <Editable hoverable value={data.raw} onSubmit={handleRemark} disabled={!isEditable()} />
        </GridColumn>
        <GridColumn width={1} style={{ marginLeft: "auto" }}>
          <TyreActions data={data} template={template} onSelect={onSelectAction} toggleShowMedia={toggleShowMedia} appointment={appointment} />
        </GridColumn>
      </GridRow>
      {showMedia && (
        <GridRow className="media-row">
          <GridColumn width={16}>
            <div className="checklist-media">
              <Images
                data={images}
                onDeleteRequest={(data: QuestionResultImage, index: number) => setSelectedDeleteMedia({ data, index })}
                canDelete={canUpdateTyreTableItem}
              />
              <Videos
                appointment={appointment}
                data={videos}
                isEditable={appointment.appointment_status_identifier !== STATUS_IDENTIFIER.CanceledStatus && canUpdateTyreTableItem}
                handleDeleteVideo={(data: QuestionResultVideo, index: number) => setSelectedDeleteMedia({ data, index })}
                handleVideoUpload={handleVideoUpload}
                handleVideoVisibility={handleVideoVisibility}
              />
            </div>
          </GridColumn>
        </GridRow>
      )}
      {data.tyre_replacements?.map(item => <TyreReplacementTableItem data={item} key={item.tyre_id} appointment={appointment} />)}

      <CustomConfirm
        type={CUSTOM_CONFIRM_TYPES.Danger}
        isOpen={selectedDeleteMedia ? true : false}
        isLoading={updateCheckQuestionItem.isPending}
        handleCancel={() => setSelectedDeleteMedia(null)}
        handleConfirm={handleDeleteMedia}
        confirmMsg={t("v8_delete_confirm_message").message || "Are you sure that you want to delete this? You can't undo this action."}
      />
    </>
  );
};
