import moment from "moment";
import { GridRow, Icon, Label } from "semantic-ui-react";

import { DATE_FORMATS } from "components/DatePicker";
import { DropdownData } from "components/SnoozeModal/components/LogHistory";
import { SnoozeItem } from "models";

type InitialLogValuesProps = {
  snoozeItem: SnoozeItem;
  snoozeTypeData: DropdownData[];
  snoozeStatusData: DropdownData[];
  snoozeDepartmentData: DropdownData[];
};

export const InitialLogValues = ({ snoozeItem, snoozeTypeData, snoozeStatusData, snoozeDepartmentData }: InitialLogValuesProps) => {
  if (snoozeItem) {
    return (
      <GridRow className="log_values">
        {!!snoozeItem.snooze_type_id && (
          <Label>
            <Icon name="time" />
            {snoozeTypeData.find(type => type.key === snoozeItem.snooze_type_id)?.text}
          </Label>
        )}
        {!!snoozeItem.snooze_status_id && (
          <Label>
            <Icon name="list alternate" />
            {snoozeStatusData.find(type => type.key === snoozeItem.snooze_status_id)?.text}
          </Label>
        )}
        {!!snoozeItem.snooze_date && (
          <Label>
            <Icon name="calendar alternate outline" />
            {moment(snoozeItem.snooze_date).format(DATE_FORMATS.dateMonthYear)}
          </Label>
        )}
        {!!snoozeItem.department_id && (
          <Label>
            <Icon name="building" />
            {snoozeDepartmentData.find(type => type.key === snoozeItem.snooze_department_id)?.text}
          </Label>
        )}
      </GridRow>
    );
  }
  return null;
};
