import { useQueryClient } from "@tanstack/react-query";
import { OnChangeFn, PaginationState } from "@tanstack/react-table";

import { useGetLeaseCompanyOptions, useGetQuestionResultStatusOptions } from "modules/LeadsDashboard/hooks";
import { LeadsNotSnoozedFilters } from "modules/LeadsDashboard/Leads/NotSnoozedLeads/components/NotSnoozedFilters";
import { QUESTION_STATUSES_DEFAULT_STATUSES } from "modules/LeadsDashboard/Leads/SnoozedLeads/hooks";
import { LeadsQueryKeys } from "modules/LeadsDashboard/queryKeys";
import { useGetChecklistsQuestionsQuery } from "modules/LeadsDashboard/service";

type Props = {
  pageSize: number;
};

export const useNotSnoozedLeadsTableFilterHandlers = ({ pageSize }: Props) => {
  const queryClient = useQueryClient();
  const { leaseCompanyOptions } = useGetLeaseCompanyOptions();
  const notSnoozedFilters = queryClient.getQueryData<LeadsNotSnoozedFilters>(LeadsQueryKeys.notSnoozedFilters);
  const { questionResultStatusOptions } = useGetQuestionResultStatusOptions();

  const currentPage = notSnoozedFilters?.page ?? 1;
  const selectedLeaseCompanyOption = leaseCompanyOptions.find(option => option.filterValue === notSnoozedFilters?.is_lease_company)?.value || "";
  const interventionQuestionOptions =
    useGetChecklistsQuestionsQuery({ dealer_ids: notSnoozedFilters?.dealer_ids || [], location_ids: notSnoozedFilters?.location_ids || [] }) || [];

  const getSelectedQuestionStatuses = () => {
    if (!notSnoozedFilters) return [];

    if (notSnoozedFilters.statuses?.length === QUESTION_STATUSES_DEFAULT_STATUSES.length) return ["all"];
    return notSnoozedFilters.statuses?.map(String) ?? ["all"];
  };

  const selectedQuestions = notSnoozedFilters?.questions
    ? notSnoozedFilters.questions.reduce((acc, questionId) => {
        const question = interventionQuestionOptions.find(element => element.filterValue === questionId);
        if (question) acc.push(question.key.toString());

        return acc;
      }, [] as string[])
    : ["all"];

  const handleQuestionResultStatusSelect = (questionStatuses: string[]) => {
    if (!notSnoozedFilters || !questionStatuses.length) return;

    const isAllOptionSelected = questionStatuses.includes("all");
    const areAllStatusesNotSelected = notSnoozedFilters.statuses?.length !== QUESTION_STATUSES_DEFAULT_STATUSES.length;
    const statuses = isAllOptionSelected && areAllStatusesNotSelected ? QUESTION_STATUSES_DEFAULT_STATUSES : questionStatuses.map(Number).filter(Boolean);

    queryClient.setQueryData<LeadsNotSnoozedFilters>(LeadsQueryKeys.notSnoozedFilters, { ...notSnoozedFilters, statuses });
  };

  const handleOnLeaseSelect = (leaseCompany: string[]) => {
    if (!notSnoozedFilters) return;

    const leaseCompanyOption = leaseCompanyOptions.find(option => option.value === leaseCompany[0]) || null;
    queryClient.setQueryData<LeadsNotSnoozedFilters>(LeadsQueryKeys.notSnoozedFilters, {
      ...notSnoozedFilters,
      is_lease_company: leaseCompanyOption ? leaseCompanyOption?.filterValue : leaseCompanyOption
    });
  };

  const handleOnQuestionSelect = (questions: string[]) => {
    if (!notSnoozedFilters) return;

    const isResetNotSelected = questions.includes("all") && notSnoozedFilters.questions !== null;
    const newQuestions = isResetNotSelected ? null : questions.map(el => Number(el.split("-")[1]));
    queryClient.setQueryData<LeadsNotSnoozedFilters>(LeadsQueryKeys.notSnoozedFilters, { ...notSnoozedFilters, questions: newQuestions });
  };

  const handleDateRangeChange = (dates: [Date | null, Date | null]) => {
    if (!notSnoozedFilters) return;

    const [start, end] = dates;
    if (start && end) queryClient.setQueryData<LeadsNotSnoozedFilters>(LeadsQueryKeys.notSnoozedFilters, { ...notSnoozedFilters, date_from: start, date_to: end });
  };

  const onPageChange = (_e: null, data: { activePage: number }) => {
    if (!notSnoozedFilters) return;
    queryClient.setQueryData<LeadsNotSnoozedFilters>(LeadsQueryKeys.notSnoozedFilters, { ...notSnoozedFilters, page: data.activePage });
  };

  const handlePaginationSelect: OnChangeFn<PaginationState> = updater => {
    const newState = typeof updater === "function" ? updater({ pageIndex: currentPage - 1, pageSize }) : updater;
    onPageChange(null, { activePage: newState.pageIndex + 1 });
  };

  return {
    handleOnLeaseSelect,
    handleDateRangeChange,
    handlePaginationSelect,
    handleOnQuestionSelect,
    handleQuestionResultStatusSelect,
    currentPage,
    selectedQuestions,
    leaseCompanyOptions,
    selectedQuestionStatuses: getSelectedQuestionStatuses(),
    selectedLeaseCompanyOption,
    questionResultStatusOptions,
    interventionQuestionOptions
  };
};
