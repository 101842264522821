export enum PATHS {
  HOME = "/",
  LOGIN = "/login",
  RESET_PASSWORD = "/reset-password",
  RESET_PASSWORD_REQUEST = "/reset-password-request",
  FORGOT_PASSWORD = "/forgot-password",
  APPOINTMENTS = "/appointments",
  APPOINTMENT_DETAIL = "/appointments/:id",
  DAYPLANNER = "/dayplanner",
  CAR_DETAIL = "/cardetails",
  CAR_DETAILS = "/cardetails/:id",
  STYLE_GUIDE = "/styleguide",
  CUSTOMER_COM = "/customer-com",
  ACCOUNT = "/account",
  LEADS_DASHBOARD = "/leads",
  WARRANTY_DASHBOARD = "/warranty",
  MANUFACTURER_DASHBOARD = "/manufacturer",
  DEALER_DOCUMENTATION = "/dealer-documentation",
  DEALER_REPORTS = "/dealer-reports",
  LOCATION_REPORTS = "/location-reports",
  EXACT_FAILURES = "/exact-dashboard",
  NOTES_MENU = "/notes-menu",
  LOCATION_LICENSE_OVERVIEW = "/location-license-overview",
  DEALER_LICENSE_OVERVIEW = "/dealer-license-overview"
}
