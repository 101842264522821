import { QueryClient } from "@tanstack/react-query";
import _join from "lodash/join";
import _split from "lodash/split";
import moment from "moment";

import { ExactCategory, ExactStandaloneLicense } from "models";
import { MonthlyInvoicingDay } from "modules/LocationLicenseOverview/constant";
import { LocationLicenseOverviewKeys } from "modules/LocationLicenseOverview/queryKeys";
import { LICENSE_CATEGORY_TYPE, LicenseData, LicenseItem, LicenseResponse, Licenses } from "modules/LocationLicenseOverview/types";
import { MOMENT_MONTHS, capitalizeFirstLetter } from "util/common";
import { ITranslation } from "util/interfaces";

export const getNextMonthlyInvoicingDate = () => {
  const currentDate = moment.utc();
  if (currentDate.date() === MonthlyInvoicingDay) return currentDate;
  if (currentDate.month() === MOMENT_MONTHS.December) return moment.utc([currentDate.year() + 1, MOMENT_MONTHS.January, MonthlyInvoicingDay]);
  return moment.utc([currentDate.year(), currentDate.month() + 1, MonthlyInvoicingDay]);
};

export const findExactItemById = (items: LicenseItem[], exactItemId: string) => {
  return items.find((item: LicenseItem) => item.ID === exactItemId);
};

export const calculateCategoryCosts = (items: LicenseItem[], category: ExactCategory) =>
  category.licenses?.reduce((total, license) => {
    const item = findExactItemById(items, license.exact_item_id);
    if (!item) return total;
    const cost = (license.price || item.StandardSalesPrice) * category.quantity;
    return total + cost;
  }, 0) || 0;

export const calculateStandaloneCosts = (items: LicenseItem[], license: ExactStandaloneLicense) => {
  const item = findExactItemById(items, license.exact_item_id);
  if (!item) return 0;
  return (license.price || item.StandardSalesPrice) * license.quantity;
};

export const calculateTotalCosts = (items: LicenseItem[], categories: ExactCategory[] | undefined, standalonesLicense: ExactStandaloneLicense[] | undefined) => {
  const categoryCosts = categories?.reduce((total, category) => total + calculateCategoryCosts(items, category), 0) || 0;
  const standaloneCosts = standalonesLicense?.reduce((total, license) => total + calculateStandaloneCosts(items, license), 0) || 0;
  return categoryCosts + standaloneCosts;
};

export const updateLicenseArray = (licenseArray: ExactStandaloneLicense[], params: ExactStandaloneLicense, condition: boolean): ExactStandaloneLicense[] => {
  const index = licenseArray.findIndex(license => license.id === params.id);
  return condition && index >= 0 ? licenseArray.with(index, { ...licenseArray[index], ...params }) : licenseArray;
};

export const updateCategoryArray = (categoryArray: ExactCategory[], params: ExactCategory, condition: boolean): ExactCategory[] => {
  const index = categoryArray.findIndex(category => category.id === params.id);
  return condition && index >= 0 ? categoryArray.with(index, { ...categoryArray[index], ...params }) : categoryArray;
};

export const getLicenseCategoryName = (license_category_type: LICENSE_CATEGORY_TYPE, t: ITranslation) => {
  if (license_category_type === LICENSE_CATEGORY_TYPE.NotSet) return "";
  const key = LICENSE_CATEGORY_TYPE[license_category_type];
  const splitKey = _split(key, /(?=[A-Z])/);
  const normalizedKey = _join(splitKey, "_");
  return t(`v8_${normalizedKey}`).message || capitalizeFirstLetter(normalizedKey.replace(/_/g, " "));
};

export const combinedLicenseData = (licensesData: Licenses | undefined, items: LicenseItem[], t: ITranslation, isMonthly: boolean): LicenseData[] => {
  if (!licensesData) return [];

  const standaloneLicenses = isMonthly ? licensesData.monthlyStandalones : licensesData.oneTimeStandalones;
  const categories = isMonthly ? licensesData.monthlyCategories : licensesData.oneTimeCategories;

  const combinedData = standaloneLicenses.reduce<LicenseData[]>((acc, item) => {
    const matchedItem = findExactItemById(items, item.exact_item_id);
    acc.push({
      id: item.id,
      categoryID: null,
      exact_item_id: item.exact_item_id,
      code: matchedItem?.Code || "",
      name: matchedItem?.Description || null,
      category: null,
      quantity: item.quantity,
      description: item.description || "",
      price: item.price || matchedItem?.StandardSalesPrice || 0,
      totalPrice: (item.price || matchedItem?.StandardSalesPrice || 0) * item.quantity,
      invoiceOn: item.invoice_on,
      tablet_model: null
    });
    return acc;
  }, []);

  categories.reduce<LicenseData[]>((acc, category) => {
    if (category.licenses?.length) {
      category.licenses.reduce((innerAcc, license) => {
        const matchedItem = findExactItemById(items, license.exact_item_id);
        innerAcc.push({
          id: license.id,
          categoryID: category.id,
          exact_item_id: license.exact_item_id,
          code: matchedItem?.Code || "",
          name: matchedItem?.Description || null,
          category: getLicenseCategoryName(category.license_category_type, t) + (category.tablet_model ? ` (${category.tablet_model})` : ""),
          quantity: category.quantity,
          description: license.description || "",
          price: license.price || matchedItem?.StandardSalesPrice || 0,
          totalPrice: (license.price || matchedItem?.StandardSalesPrice || 0) * category.quantity,
          invoiceOn: category.invoice_on,
          tablet_model: category.tablet_model
        });
        return innerAcc;
      }, acc);
    } else {
      acc.push({
        id: 0,
        categoryID: category.id,
        exact_item_id: "",
        code: "",
        name: null,
        category: getLicenseCategoryName(category.license_category_type, t) + (category.tablet_model ? ` (${category.tablet_model})` : ""),
        quantity: category.quantity,
        description: "",
        price: 0,
        totalPrice: 0,
        invoiceOn: category.invoice_on,
        tablet_model: category.tablet_model
      });
    }
    return acc;
  }, combinedData);

  return combinedData.sort((a, b) => b.code.localeCompare(a.code));
};

export const updateLicenseData = (updateFn: (licenses: Licenses) => Licenses, locationID: number, queryClient: QueryClient) => {
  const queryKey = LocationLicenseOverviewKeys.licenses({ dealer_location_id: locationID });
  const licenses = queryClient.getQueryData<Licenses>(queryKey);
  if (!licenses) return;
  queryClient.setQueryData(queryKey, updateFn(licenses));
  return licenses;
};

export const filterLicenseData = (items: LicenseItem[], value: string | undefined) => {
  const filteredValues: LicenseItem[] = value
    ? items.filter(item => {
        const searchTerm = value.toLowerCase();
        const combined = [item.Code, item.Description].join(" - ").toLowerCase();
        return combined.includes(searchTerm);
      })
    : [];

  return filteredValues;
};

export const transformData = (data: LicenseResponse) => {
  const { monthlyCategories, oneTimeCategories } = (data.categories ?? []).reduce(
    (acc, item) => {
      if (item.one_time) acc.oneTimeCategories.push(item);
      else acc.monthlyCategories.push(item);
      return acc;
    },
    { monthlyCategories: [] as ExactCategory[], oneTimeCategories: [] as ExactCategory[] }
  );

  const { monthlyStandalones, oneTimeStandalones } = (data.standalone_licenses ?? []).reduce(
    (acc, item) => {
      if (item.invoice_on) acc.oneTimeStandalones.push(item);
      else acc.monthlyStandalones.push(item);
      return acc;
    },
    { monthlyStandalones: [] as ExactStandaloneLicense[], oneTimeStandalones: [] as ExactStandaloneLicense[] }
  );

  return { monthlyCategories, monthlyStandalones, oneTimeCategories, oneTimeStandalones } as Licenses;
};
