import { Row } from "@tanstack/react-table";
import moment from "moment";
import React, { ChangeEvent, MouseEvent, useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import { Button, ButtonGroup, Dropdown, Form, Icon, Input, Message, Modal, ModalProps, TextArea } from "semantic-ui-react";

import { DATE_FORMATS, FileDragDrop, PinTypeIcon } from "components";
import { InterventionSubitemsModal } from "components";
import "components/InterventionModal/InterventionModal.scss";
import { useDealersLocations, usePinOptions, useUser } from "hooks";
import { INTERVENTION_ELEMENT_TYPE, InterventionAttachment, InterventionElement, PinModel, PinOptions } from "models";
import SubitemsTable from "modules/AppointmentDetails/components/Interventions/components/Subitems/components/SubitemsTable";
import { PinType } from "modules/AppointmentDetails/components/Interventions/InterventionItem";
import { InterventionData, useIntervention } from "modules/AppointmentDetails/hooks";
import { getNameFromURL } from "util/common";
import { ITranslation } from "util/interfaces";

export interface InterventionElementWithTyre extends InterventionElement {
  is_tyre_replacement?: boolean;
  customer_approved?: boolean;
}

interface InterventionDataWithTyre extends Omit<InterventionData, "elements"> {
  elements: InterventionElementWithTyre[];
}

interface InterventionModalProps extends ModalProps {
  isNew: boolean;
  t: ITranslation;
  data?: InterventionDataWithTyre;
  appointmentId?: number;
  onSubmitData?: (data: InterventionData) => void;
  onSuccess?: () => void;
  showPinDropdown?: boolean;
  showFileUpload?: boolean;
}

const InterventionModal = ({
  open,
  onClose,
  data,
  t,
  appointmentId,
  onSubmitData,
  onSuccess,
  showPinDropdown = true,
  showFileUpload = true,
  ...rest
}: InterventionModalProps) => {
  const [initialValues, setInitialValues] = useState<Partial<InterventionData> | undefined>();
  const [customerOk, setCustomerOk] = useState(data?.customer_ok || false);
  const [label, setLabel] = useState<PinType | null>(null);
  const [interventionSubItemModal, showInterventionSubItemModal] = useState(false);
  const [interventionSubItems, setInterventionSubItems] = useState<InterventionElementWithTyre[]>([]);
  const [canSubmit, setCanSubmit] = useState(false);
  const [openDropdown, setOpenDropdown] = useState(false);
  const [interventionAttachments, setInterventionAttachments] = useState<InterventionAttachment[]>([]);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const { isKeyLoopLocation } = useDealersLocations();

  const isMultipleTyresSelected = interventionSubItems.filter(item => item.is_tyre_replacement).length > 1;

  useEffect(() => {
    setInitialValues(data);
    setInterventionAttachments(data?.attachments || []);
  }, [data]);

  useEffect(() => {
    if (initialValues) {
      const hasChanged = Object.keys(initialValues || {}).some(key => {
        const dataKey = key as keyof InterventionData;
        return (initialValues as InterventionData)[dataKey] !== "";
      });

      setCanSubmit(interventionSubItems.length ? !isMultipleTyresSelected : hasChanged);
    }
  }, [initialValues]);

  const { addIntervention, addPinItem, uploadAttachment } = useIntervention(Number(data?.appointment_id || appointmentId));
  const user = useUser();
  const { data: pinOptions } = usePinOptions();

  const pinTypeData: PinType[] = pinOptions?.pin_types?.map((pinType: PinOptions) => {
    return { key: pinType.name, text: t(`${pinType.name}`).message, value: pinType.id, icon: <PinTypeIcon pin_type_id={pinType.id} /> };
  });

  useEffect(() => {
    if (!open) return;

    const createInterventionSubItem = (description: string) => ({
      description,
      element_type: INTERVENTION_ELEMENT_TYPE.Text,
      dms_nr: `claire_${moment().utc().format(DATE_FORMATS.ISOFormat)}`
    });

    if (data?.elements?.length) {
      const customerApprovedTyreReplacements = data.elements.filter(tyre => tyre.customer_approved);
      const tyreReplacements = data.elements;

      setInterventionSubItems(customerApprovedTyreReplacements.length ? customerApprovedTyreReplacements : tyreReplacements);
    } else if (data?.description) {
      setInterventionSubItems([createInterventionSubItem(data.description)]);
    } else if (isKeyLoopLocation) {
      setInterventionSubItems([createInterventionSubItem(`Claire job ${user?.first_name} ${user?.last_name}`)]);
    }
  }, [open]);

  const resetState = () => {
    setOpenDropdown(false);
    setInitialValues(undefined);
    setCustomerOk(false);
    setLabel(null);
    setInterventionSubItems([]);
    setCanSubmit(false);
    setFormSubmitted(false);
  };

  const toggleCustomerOk = () => {
    setCustomerOk(prev => !prev);
  };

  const handleClose = (e: MouseEvent<HTMLElement, globalThis.MouseEvent>) => {
    setInterventionSubItems([]);
    setLabel(null);
    onClose && onClose(e, rest);
  };

  const toggleInterventionSubitem = () => {
    showInterventionSubItemModal(prev => !prev);
  };

  const handleAddSubitem = async (description: string) => {
    setInterventionSubItems([
      ...interventionSubItems,
      { description, element_type: INTERVENTION_ELEMENT_TYPE.Text, dms_nr: `claire_${moment().utc().format(DATE_FORMATS.ISOFormat)}` }
    ]);
    toggleInterventionSubitem();
  };

  const handleSubitemDelete = async (element: Row<InterventionElement>) => {
    const interventionSubItem = interventionSubItems.filter((_, idx) => idx !== element.index);
    setInterventionSubItems(interventionSubItem);

    setInitialValues(prevState => {
      return {
        ...prevState,
        price: interventionSubItem?.length === 1 ? (Number(interventionSubItem[0].price) ?? 0) : prevState?.price
      };
    });
  };

  const handleNewIntervention = async (e: React.FormEvent<HTMLFormElement>) => {
    setFormSubmitted(true);
    if (!canSubmit) return;

    const formData: any = Object.fromEntries(new FormData((e as any).target));
    const intervention: InterventionData = {
      ...formData,
      price: Number(formData.price),
      customer_ok: customerOk,
      appointment_id: data?.appointment_id || appointmentId,
      dms_nr: `claire_${moment().utc().format(DATE_FORMATS.ISOFormat)}`,
      elements: interventionSubItems,
      is_local: true,
      visible_to_customer: true,
      question_result_id: data?.question_result_id,
      attachments: interventionAttachments,
      checkin_remark_id: data?.checkin_remark_id,
      checkin_result_id: data?.checkin_result_id
    };
    if (onSubmitData) {
      onSubmitData(intervention);
      handleClose(e as unknown as MouseEvent<HTMLElement, globalThis.MouseEvent>);
      return;
    }
    const { id: intervention_id } = await addIntervention.mutateAsync(intervention);
    if (label && label.value > 0 && intervention_id) {
      const pin: PinModel = {
        pin_type_id: label.value,
        order_status: null,
        intervention_id,
        appointment: null,
        appointment_id: data?.appointment_id || appointmentId,
        intervention: null,
        user_id: user ? user.id : 0,
        question_result: null,
        note_attachments: [],
        created_on: moment().utc().format(DATE_FORMATS.ISOFormat)
      };
      await addPinItem.mutateAsync(pin);
    }
    onSuccess?.();
    handleClose(e as unknown as MouseEvent<HTMLElement, globalThis.MouseEvent>);
  };

  const handleOnChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setInitialValues(prevState => {
      return {
        ...prevState,
        [name]: value
      };
    });
  };

  const toggleDropdown = (e: React.SyntheticEvent) => {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }

    setOpenDropdown(prev => !prev);
  };

  const handleUploadAttachment = async (file: File) => {
    const appointmentID = data?.appointment_id || appointmentId;
    if (!file || !appointmentID) return;

    const extension = file.name.lastIndexOf(".") > -1 ? file.name.slice(file.name.lastIndexOf(".") + 1) : "unknown";
    const uploadFile = new File([file], file.name, { type: file.type || extension });

    const attachmentUrl = await uploadAttachment(appointmentID, uploadFile);

    if (attachmentUrl) {
      const newAttachment: InterventionAttachment = {
        url: attachmentUrl,
        created_on: moment().utc().format(DATE_FORMATS.ISOFormat),
        updated_on: moment().utc().format(DATE_FORMATS.ISOFormat),
        name: getNameFromURL(attachmentUrl),
        type: file.type
      };
      setInterventionAttachments(prev => {
        if (prev?.length) {
          return [...prev, newAttachment];
        }
        return [newAttachment];
      });
    }
  };

  const handleOpenAttachment = (url: string) => {
    window.open(url, "_blank");
  };

  const onDeleteAttachment = (url: string) => {
    const updatedInterventionAttachments = interventionAttachments.filter(attachment => attachment.url !== url);
    setInterventionAttachments(updatedInterventionAttachments);
  };

  const handleFileUpload = (files: FileList | null) => {
    if (!files) return;

    [...files].forEach(file => {
      handleUploadAttachment(file);
    });
  };

  return (
    <Modal open={open} onClose={onClose} onOpen={resetState} closeOnDimmerClick className="InterventionModal">
      <Modal.Header>
        <div className="notes-modal-header-icon">
          <Icon className="screwdriver wrench" />
        </div>
        <p className="intervention-modal-title">
          {data ? t("v8_convert_to_intervention").message || "Convert to Intervention" : t("v8_add_intervention").message || "Add Intervention"}{" "}
        </p>
      </Modal.Header>
      <Modal.Content>
        <div>
          <Form id="new-intervention" onSubmit={handleNewIntervention} onChange={handleOnChange}>
            <Form.Group>
              <Form.Field width={10} required>
                <label>{t("v8_title").message || "Title"}</label>
                <Input defaultValue={data?.title} placeholder={t("v8_title").message || "Title"} fluid name="title" required />
              </Form.Field>

              <Form.Field width={4}>
                <label>{t("v8_fixed_price").message || "Fixed Price"}</label>
                <Input name="price" defaultValue={initialValues?.price} type="number" step={0.01} label={{ basic: true, content: "€" }} labelPosition="right" />
              </Form.Field>

              {showPinDropdown && (
                <Form.Field width={1}>
                  <label>{t("v8_label").message || "Label"}</label>
                  <ButtonGroup className="withdropdown">
                    <Button className={`withdropdownButton ${!label ? "grey" : "black"}`} onClick={toggleDropdown}>
                      {label ? label.icon : <PinTypeIcon pin_type_id={0} />}
                    </Button>
                    <Dropdown className="button icon dropdownTrigger" floating open={openDropdown} onOpen={toggleDropdown} onClose={toggleDropdown}>
                      <Dropdown.Menu>
                        <Dropdown.Item onClick={() => setLabel(null)} className="no-label">
                          <Icon name="pin" className="icon-margin-left" />
                          {"No Label"}
                        </Dropdown.Item>
                        {pinTypeData?.map((pinType: PinType) => (
                          <Dropdown.Item key={pinType.key} onClick={() => setLabel(pinType)} icon={pinType.icon} text={pinType.text} />
                        ))}
                      </Dropdown.Menu>
                    </Dropdown>
                  </ButtonGroup>
                </Form.Field>
              )}

              <Form.Field width={1} className="mt-25">
                <Button onClick={toggleCustomerOk} type="button" className="wdLightGrey centerIcon greyBorder customer">
                  <Icon className={`user check ${customerOk ? "green" : "red"}`} />
                </Button>
              </Form.Field>
            </Form.Group>

            <Form.Group>
              <Form.Field width={16}>
                <label>{t("v8_remarks").message || "Remarks"}</label>
                <TextArea defaultValue={data?.description} placeholder={t("v8_enter_remarks_here").message || "Enter remarks here"} rows={2} name="description" />
              </Form.Field>
            </Form.Group>
          </Form>

          {showFileUpload && (
            <>
              <FileDragDrop id="new-intervention-image" onFileUpload={handleFileUpload} />
              {interventionAttachments.length > 0 && (
                <div className="new-attachment-container">
                  {interventionAttachments.map((item: InterventionAttachment) => {
                    return (
                      <div key={item.url} className="attachment-view">
                        <div className="attachment-name" onClick={() => handleOpenAttachment(item.url)}>
                          <Icon className="image" />
                          <p className="ellipsis">{item.name}</p>
                        </div>
                        <div className="attachment-delete">
                          {item.created_on && (
                            <p>{`${t("v8_uploaded_on").message || "Uploaded on"} ${moment(item.created_on).format(DATE_FORMATS.dateMonthYearTime)}`}</p>
                          )}
                          <Icon className="trash grey" onClick={() => onDeleteAttachment(item.url)} />
                        </div>
                      </div>
                    );
                  })}
                </div>
              )}
            </>
          )}

          <Button color="green" type="button" onClick={toggleInterventionSubitem} className="-appointment-statu left-icon mt-14">
            <Icon className="plus" />
            {t("v8_subitems").message || "Subitems"}
          </Button>
        </div>
        {interventionSubItemModal && <InterventionSubitemsModal onSubmit={handleAddSubitem} onClose={toggleInterventionSubitem} />}
        {interventionSubItems && interventionSubItems.length > 0 && (
          <SubitemsTable elements={interventionSubItems} showActionButton={true} onDeleteSubitem={handleSubitemDelete} />
        )}
        {formSubmitted && isMultipleTyresSelected && (
          <Message error content={t("v8_cannot_have_more_than_one_tyre_replacement_subitem").message || "Cannot have more than one tyre replacement subitem"} />
        )}
      </Modal.Content>
      <Modal.Actions>
        <div className="action-buttons">
          <Button color="light" onClick={handleClose}>
            {t("v8_cancel").message || "Cancel"}
            <Icon className="xmark" />
          </Button>
          <Button color="green" form="new-intervention" type="submit">
            {t("v8_save").message || "Save"}
            <Icon className="check" />
          </Button>
        </div>
      </Modal.Actions>
    </Modal>
  );
};

export default withTranslation()(InterventionModal);
