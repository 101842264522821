import moment from "moment";

import { DATE_FORMATS } from "components";
import { DATE_ORDER_BY } from "modules/Appointments";
import "modules/Appointments/components/AppointmentTable/components/AppointmentTableDateColumnCell/AppointmentTableDateColumnCell.scss";

type AppointmentTableDateColumnCellProps = {
  dateColumnAccessor: string;
  value: { date: string | null; isPinned: boolean };
  isTodaysList: boolean;
};

export const AppointmentTableDateColumnCell = ({ dateColumnAccessor, value, isTodaysList }: AppointmentTableDateColumnCellProps) => {
  const dateDiff = Number(moment(Date.now()).startOf("day").diff(moment(value.date).startOf("day"), "days"));

  const renderDateDiff = () => (value.isPinned && dateDiff && isTodaysList ? (dateDiff > 0 ? `+${dateDiff}` : dateDiff) : null);

  switch (dateColumnAccessor) {
    case DATE_ORDER_BY.APPOINTMENT_DATE_ASCENDING:
    case DATE_ORDER_BY.APPOINTMENT_DATE_DESCENDING:
      return (
        <div className="AppointmentTableDateColumnCellTimeCarApp">
          {value.date ? moment(value.date).format("DD-MM-YY") : ""}
          <span className="date-diff-value">{renderDateDiff()}</span>
        </div>
      );
    case DATE_ORDER_BY.SCHEDULED_OUT_DATE_ASCENDING:
    case DATE_ORDER_BY.SCHEDULED_OUT_DATE_DESCENDING:
      return (
        <div className={moment(new Date()).isAfter(value.date) ? "past-car-return-time" : ""}>
          {value.date ? (
            <span>
              <span>{moment(value.date).format(DATE_FORMATS.dateMonthYear)}</span>
              <span style={{ fontWeight: "bold" }}>&nbsp;&nbsp;{moment(value.date).format(DATE_FORMATS.time)}</span>
            </span>
          ) : (
            ""
          )}
        </div>
      );
    default:
      return null;
  }
};
