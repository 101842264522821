import _cloneDeep from "lodash/cloneDeep";
import moment from "moment";

import { DATE_FORMATS } from "components";
import { Appointment, DealerLocation, SnoozeItem } from "models";

export const getLocationName = (locationId: number | null, locations: DealerLocation[]) => locations.find(location => location.id === locationId)?.name || "";
const formatDate = (date: string | null | undefined) => (date ? moment(date).format(DATE_FORMATS.dateMonthYear) : "");
export const getFormattedDate = (date: string | null | undefined) => {
  if (!date) return "";

  const isValidDate = !date.startsWith("0001-01-01T00:00:00");
  if (moment.isMoment(date) || isValidDate) return formatDate(date);
};

export const groupWOResults = (snoozeItems: SnoozeItem[]) => {
  const clonedSnoozeItems = _cloneDeep(snoozeItems);

  clonedSnoozeItems.forEach((item, index) => {
    if (clonedSnoozeItems[index + 1] && item.appointment_id === clonedSnoozeItems[index + 1].appointment_id) {
      clonedSnoozeItems[index + 1].appointment = {
        ...new Appointment(),
        reg_number: "",
        wo_nr: "0",
        time_car_app: "",
        customer_contract: null,
        customer_driver: null,
        customer_owner: null
      };
    }
  });

  return clonedSnoozeItems;
};

// TODO: Revisit this, for now it is the same as from v7, the refinement wasn't okay for v8
//@ts-nocheck
export const groupSnoozeItems = (snoozeItems: SnoozeItem[]) => {
  const interventions: any[] = [],
    questions: any[] = [],
    interventions_offsets: any[] = [],
    questions_offsets: any[] = [];

  let intervention_offset = 0,
    question_offset = 0;

  snoozeItems.forEach(i => {
    if (i.intervention_id) {
      if (interventions_offsets[i.intervention_id] === undefined) {
        interventions_offsets[i.intervention_id] = intervention_offset++;
        interventions[interventions_offsets[i.intervention_id]] = { ...i, history: [i] };
      } else {
        if (i.created_on > interventions[interventions_offsets[i.intervention_id]].created_on)
          interventions[interventions_offsets[i.intervention_id]] = {
            ...i,
            history: interventions[interventions_offsets[i.intervention_id]].history.concat(i)
          };
        else interventions[interventions_offsets[i.intervention_id]].history.push(i);
      }
    } else if (i.question_result_id) {
      if (questions_offsets[i.question_result_id] === undefined) {
        questions_offsets[i.question_result_id] = question_offset++;
        questions[questions_offsets[i.question_result_id]] = { ...i, history: [i] };
      } else {
        if (i.created_on > questions[questions_offsets[i.question_result_id]].created_on)
          questions[questions_offsets[i.question_result_id]] = { ...i, history: questions[questions_offsets[i.question_result_id]].history.concat(i) };
        else questions[questions_offsets[i.question_result_id]].history.push(i);
      }
    }
  });

  const sortedResult = [...interventions, ...questions].sort((a, b) => (b.appointment.time_car_app > a.appointment.time_car_app ? 1 : -1));
  return groupWOResults(sortedResult);
};

// TODO: this was per refinement, but something is off, need to check it.
export const groupSnoozeItemsAdvanced = (results: SnoozeItem[]) => {
  const snoozedItemsByQuestionId = new Map<number, SnoozeItem[]>();
  const snoozedItemsByInterventionId = new Map<number, SnoozeItem[]>();

  results.forEach(result => {
    if (result.question_result_id) {
      const items = snoozedItemsByQuestionId.get(result.question_result_id);
      if (items) {
        if (items[0].created_on < result.created_on) {
          items.unshift(result);
        } else {
          items.push(result);
        }
      } else {
        snoozedItemsByQuestionId.set(result.question_result_id, [result]);
      }
    } else if (result.intervention_id) {
      const items = snoozedItemsByInterventionId.get(result.intervention_id);
      if (items) {
        if (items[0].created_on < result.created_on) {
          items.unshift(result);
        } else {
          items.push(result);
        }
      } else {
        snoozedItemsByInterventionId.set(result.intervention_id, [result]);
      }
    }
  });

  const items = [...snoozedItemsByQuestionId.values(), ...snoozedItemsByInterventionId.values()];
  // items.sort((a, b) => b[0].appointment?.time_car_app > a[0].appointment?.time_car_app);
  return items;
};
