import { useQueryClient } from "@tanstack/react-query";
import { OnChangeFn, PaginationState } from "@tanstack/react-table";

import { useGetLeaseCompanyOptions } from "modules/LeadsDashboard/hooks";
import { LeadsCarsFilter } from "modules/LeadsDashboard/Leads/CarsLeads/components";
import { LeadsQueryKeys } from "modules/LeadsDashboard/queryKeys";

type Props = {
  pageSize: number;
};

export const useCarsTableFiltersHandlers = ({ pageSize }: Props) => {
  const queryClient = useQueryClient();
  const { leaseCompanyOptions } = useGetLeaseCompanyOptions();
  const carsFilters = queryClient.getQueryData<LeadsCarsFilter>(LeadsQueryKeys.carsFilters);

  const currentPage = carsFilters?.page ?? 1;
  const selectedLeaseCompanyOption = leaseCompanyOptions.find(option => option.filterValue === carsFilters?.is_lease_company)?.value || "";

  const handleOnLeaseSelect = (leaseCompany: string[]) => {
    if (!carsFilters) return;
    const leaseCompanyOption = leaseCompanyOptions.find(option => option.value === leaseCompany[0]) || null;
    queryClient.setQueryData<LeadsCarsFilter>(LeadsQueryKeys.carsFilters, {
      ...carsFilters,
      is_lease_company: leaseCompanyOption ? leaseCompanyOption?.filterValue : leaseCompanyOption
    });
  };

  const onPageChange = (_e: null, data: { activePage: number }) => {
    if (!carsFilters) return;
    queryClient.setQueryData<LeadsCarsFilter>(LeadsQueryKeys.carsFilters, { ...carsFilters, page: data.activePage });
  };

  const handlePaginationSelect: OnChangeFn<PaginationState> = updater => {
    const newState = typeof updater === "function" ? updater({ pageIndex: currentPage - 1, pageSize }) : updater;
    onPageChange(null, { activePage: newState.pageIndex + 1 });
  };

  return { handleOnLeaseSelect, selectedLeaseCompanyOption, leaseCompanyOptions, currentPage, handlePaginationSelect };
};
