import { Dispatch, FormEvent, SetStateAction, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Checkbox, CheckboxProps, Dropdown, Form, Grid, GridColumn, Icon, Input, TextArea } from "semantic-ui-react";

import { PinLog, useCan } from "components";
import { usePinOptions, useUser } from "hooks";
import { Appointment, Intervention, InterventionAttachment, NOTE_TYPES, PIN_ORDER, PIN_TYPE, PinModel, PinOptions, STATUS_IDENTIFIER } from "models";
import { PinType } from "modules/AppointmentDetails/components";
import { NoteAttachments, PinOverview } from "modules/AppointmentDetails/components/Interventions/components";
import "modules/AppointmentDetails/components/Interventions/components/PinCategory/PinCategory.scss";
import { useGetNotes, useIntervention } from "modules/AppointmentDetails/hooks";
import { ITranslation } from "util/interfaces";

type PinCategoryProps = {
  data: Intervention;
  appointment: Appointment | null;
  latestPinData?: PinModel;
  pinType: PinType;
  pinTypeData: PinType[];
  setHasChanges: Dispatch<SetStateAction<boolean>>;
};

export const OrderStatusColors = {
  [PIN_ORDER.ToOrder]: "blue",
  [PIN_ORDER.SameDay]: "green",
  [PIN_ORDER.NextDay]: "orange",
  [PIN_ORDER.BackOrder]: "red",
  [PIN_ORDER.Arrived]: "yellow"
};

const PinToNoteTypeMapping = {
  [PIN_TYPE.Warranty]: NOTE_TYPES.WarrantyPin,
  [PIN_TYPE.Recall]: NOTE_TYPES.RecallPin,
  [PIN_TYPE.Remarks]: NOTE_TYPES.RemarkPin
};

enum NOTE_TYPE_SELECTION {
  WarrantyTypeId = "warranty_type_id",
  PinStatusId = "pin_status_id",
  OrderStatus = "order_status",
  SupportNr = "support_nr",
  ClaimNr = "claim_nr",
  RefNr = "ref_nr",
  KeepParts = "keep_parts",
  Important = "important"
}

type DropDownChangeFieldName = NOTE_TYPE_SELECTION.WarrantyTypeId | NOTE_TYPE_SELECTION.PinStatusId | NOTE_TYPE_SELECTION.OrderStatus;
type InputChangeFieldName = NOTE_TYPE_SELECTION.SupportNr | NOTE_TYPE_SELECTION.ClaimNr | NOTE_TYPE_SELECTION.RefNr;

export const PinCategory = ({ data, appointment, latestPinData, pinType, pinTypeData, setHasChanges }: PinCategoryProps) => {
  const t = useTranslation().t as ITranslation;
  const [noteDropdownValue, setNoteDropdownValue] = useState<number | undefined>(undefined);
  const [pinWarrantyID, setPinWarrantyID] = useState(latestPinData?.warranty_type_id || null);
  const [supportNr, setSupportNr] = useState(latestPinData?.support_nr || "");
  const [claimNr, setClaimNr] = useState(latestPinData?.claim_nr || "");
  const [refNr, setRefNr] = useState(latestPinData?.ref_nr || "");
  const [note, setNote] = useState("");
  const [attachments, setAttachments] = useState<InterventionAttachment[]>([]);
  const [keepParts, setKeepParts] = useState(false);
  const [important, setImportant] = useState(false);

  const [status, setStatus] = useState(latestPinData?.pin_status_id || null);
  const [orderStatus, setOrderStatus] = useState(latestPinData?.order_status || null);
  const [showPrintOverview, setShowPrintOverview] = useState(false);

  const { addPinItem, addAttachmentToInterventionMutation } = useIntervention(data.appointment_id);
  const user = useUser();
  const { data: pinOptions } = usePinOptions();
  const canUpdateInterventions = useCan("update", "appointments");
  const noteTypeId = PinToNoteTypeMapping[pinType.value as keyof typeof PinToNoteTypeMapping];
  const noteSuggestionOptions = useGetNotes(noteTypeId);

  const pinStatusData = pinOptions?.pin_statuses?.map((pinStatus: PinOptions) => ({ key: pinStatus.name, text: t(`${pinStatus.name}`).message, value: pinStatus.id }));

  const pinOrderData = pinOptions?.pin_order_statuses?.map((pinOrder: PinOptions) => ({
    key: pinOrder.name,
    text: t(`${pinOrder.name}`).message,
    value: pinOrder.id,
    image: <Icon className="shopping cart" color={OrderStatusColors[pinOrder.id as PIN_ORDER]} />
  }));

  const pinWarrantyData = pinOptions?.warranty_types?.map((pinWarranty: PinOptions) => ({
    key: pinWarranty.name,
    text: t(`${pinWarranty.name}`).message,
    value: pinWarranty.id
  }));

  useEffect(() => {
    setPinWarrantyID(latestPinData?.warranty_type_id || null);
    setSupportNr(latestPinData?.support_nr || "");
    setClaimNr(latestPinData?.claim_nr || "");
    setRefNr(latestPinData?.ref_nr || "");
    setStatus(latestPinData?.pin_status_id || null);
    setOrderStatus(latestPinData?.order_status || null);
  }, [latestPinData]);

  const hasUpdated = () => {
    if (!canUpdateInterventions) return false;
    if (latestPinData?.support_nr !== supportNr) return true;
    if ((latestPinData?.warranty_type_id || null) !== pinWarrantyID) return true;
    if ((latestPinData?.pin_status_id || null) !== status) return true;
    if ((latestPinData?.order_status || null) !== orderStatus) return true;
    if (latestPinData?.claim_nr !== claimNr) return true;
    if (latestPinData?.ref_nr !== refNr) return true;
    if (attachments.length) return true;
    if (note) return true;
    if (!supportNr && !claimNr && !refNr && !note) return false;
    return false;
  };

  const onDropdownChange = (name: DropDownChangeFieldName, value: number) => {
    if (name === NOTE_TYPE_SELECTION.WarrantyTypeId) setPinWarrantyID(value);
    if (name === NOTE_TYPE_SELECTION.PinStatusId) setStatus(value);
    if (name === NOTE_TYPE_SELECTION.OrderStatus) setOrderStatus(value || null);
  };

  const onInputChange = (name: InputChangeFieldName, value: string) => {
    if (name === NOTE_TYPE_SELECTION.SupportNr) setSupportNr(value);
    if (name === NOTE_TYPE_SELECTION.ClaimNr) setClaimNr(value);
    if (name === NOTE_TYPE_SELECTION.RefNr) setRefNr(value);
  };

  const onCheckboxChange = (_: FormEvent<HTMLInputElement>, data: CheckboxProps) => {
    if (data.name === NOTE_TYPE_SELECTION.Important) setImportant(!!data.checked);
    if (data.name === NOTE_TYPE_SELECTION.KeepParts) setKeepParts(!!data.checked);
  };

  const onTextAreaChange = (data: string) => setNote(data);
  const openModal = () => setShowPrintOverview(true);
  const closeModal = () => setShowPrintOverview(false);

  const handleNewPinItem = async () => {
    const pin: PinModel = {
      warranty_type_id: pinWarrantyID,
      support_nr: supportNr,
      pin_status_id: status,
      pin_type_id: pinType.value,
      order_status: orderStatus,
      ref_nr: refNr,
      claim_nr: claimNr,
      intervention_id: data?.id,
      appointment_id: data?.appointment_id,
      intervention: data,
      user_id: user ? user.id : 0,
      question_result: null,
      note_attachments: attachments.map(attach => attach.url),
      note,
      keep_parts: keepParts,
      visible_important_items: important
    };

    attachments.forEach(attach =>
      addAttachmentToInterventionMutation.mutate({ url: attach.url, name: attach.name, intervention_id: attach.intervention_id!, type: attach.type! })
    );
    await addPinItem.mutateAsync(pin);
  };

  useEffect(() => {
    setHasChanges(hasUpdated());
  }, [note, supportNr, claimNr, refNr, attachments]);

  useEffect(() => {
    if (addPinItem.isSuccess) {
      setNote("");
      setImportant(false);
      setKeepParts(false);
      setAttachments([]);
      setHasChanges(false);
    }
  }, [addPinItem.isSuccess]);

  const pinLogAttachments = (data?.pin_history || []).flatMap(history => history.note_attachments || []).map(attachment => ({ url: attachment }));

  return (
    <div className="PinType">
      <Form>
        <div className="order-container">
          <div className="dropdowns-container">
            <Form.Field>
              <label>{t("v8_support").message || "Support"} #</label>
              <Input value={supportNr} fluid name="support_nr" onChange={evt => onInputChange(evt.target.name as NOTE_TYPE_SELECTION.SupportNr, evt.target.value)} />
            </Form.Field>

            <Form.Field>
              <label>{t("v8_claim").message || "Claim"} #</label>
              <Input value={claimNr} fluid name="claim_nr" onChange={evt => onInputChange(evt.target.name as NOTE_TYPE_SELECTION.ClaimNr, evt.target.value)} />
            </Form.Field>

            <Form.Field>
              <label>{t("v8_ref").message || "Ref"} #</label>
              <Input value={refNr} fluid name="ref_nr" onChange={evt => onInputChange(evt.target.name as NOTE_TYPE_SELECTION.RefNr, evt.target.value)} />
            </Form.Field>

            <Form.Field>
              <label>{t("v8_order_status").message || "Order status"}</label>
              <Dropdown
                value={orderStatus as number}
                onChange={(_, { name, value }) => onDropdownChange(name, Number(value))}
                name="order_status"
                clearable={!!orderStatus}
                placeholder={t("v8_select_order_status").message || "Select order status"}
                fluid
                selection
                options={pinOrderData}
              />
            </Form.Field>
          </div>

          <div className="checkbox-container">
            <Icon className="setting" color="grey" />

            <span>{t("v8_keep_parts").message || "Keep Parts"}</span>
            <Checkbox name="keep_parts" onChange={onCheckboxChange} checked={keepParts} />
          </div>
        </div>

        <Grid columns={2}>
          {pinType.value === PIN_TYPE.Warranty && (
            <GridColumn>
              <Form.Field>
                <label>{t("v8_warranty_type").message || "Warranty type"}</label>
                <Dropdown
                  value={pinWarrantyID || undefined}
                  onChange={(_, { name, value }) => onDropdownChange(name, Number(value))}
                  name="warranty_type_id"
                  placeholder={t("v8_select_type").message || "Select type"}
                  fluid
                  selection
                  options={pinWarrantyData}
                />
              </Form.Field>
            </GridColumn>
          )}
          {pinType.value !== PIN_TYPE.Remarks && (
            <GridColumn>
              <Form.Field>
                <label>{t("v8_pin_status").message || "Pin Status"}</label>
                <Dropdown
                  value={status || undefined}
                  onChange={(_, { name, value }) => onDropdownChange(name, Number(value))}
                  name="pin_status_id"
                  placeholder={t("v8_select_status").message || "Select status"}
                  fluid
                  selection
                  options={pinStatusData}
                />
              </Form.Field>
            </GridColumn>
          )}
        </Grid>

        <Grid columns={2}>
          <GridColumn width={14}>
            {noteSuggestionOptions.length > 0 && (
              <Form.Field>
                <label>{t("v8_note").message || "Note"}</label>
                <Dropdown
                  selection
                  fluid
                  selectOnBlur={false}
                  options={noteSuggestionOptions}
                  value={noteDropdownValue}
                  name="note_suggestion_id"
                  placeholder={t("choose_answer").message || "Choose answer"}
                  onChange={(_, { value }) => {
                    setNoteDropdownValue(value as number);
                    onTextAreaChange(noteSuggestionOptions.find(item => item.value === value)?.text || "");
                  }}
                />
              </Form.Field>
            )}
          </GridColumn>

          <GridColumn width={2} verticalAlign="bottom">
            <Form.Field>
              <div className="checkbox-container">
                <Icon className="circle exclamation" color="red" />

                <span>{t("v8_important").message || "Important"}</span>
                <Checkbox name="important" onChange={onCheckboxChange} checked={important} />
              </div>
            </Form.Field>
          </GridColumn>
        </Grid>

        <Grid>
          <GridColumn>
            <Form.Field>
              {!noteSuggestionOptions.length && <label>{t("v8_note").message || "Note"}</label>}
              <TextArea name="note" rows={2} placeholder={t("v8_note").message || "Note"} value={note} onChange={evt => onTextAreaChange(evt.target.value)} />
            </Form.Field>
          </GridColumn>
        </Grid>

        <Grid>
          <GridColumn>
            <Form.Field>
              <NoteAttachments appointmentId={data.appointment_id} interventionId={data.id} attachments={attachments} setAttachments={setAttachments} />
            </Form.Field>
          </GridColumn>
        </Grid>

        <div className="warranty-actions">
          <Button color="grey" className="-appointment-status" onClick={openModal}>
            <Icon className="print black" />
            {t("v8_print_overview").message || "Print Overview"}
          </Button>

          <Button
            color="green"
            type="button"
            disabled={!hasUpdated() || appointment?.appointment_status_identifier === STATUS_IDENTIFIER.CanceledStatus}
            onClick={handleNewPinItem}
          >
            <span>{data.pin_history && data.pin_history.length > 0 ? t("v8_update").message || "Update" : t("v8_save").message || "Save"}</span>
            <Icon className="check" />
          </Button>
        </div>

        <PinLog
          attachments={pinLogAttachments}
          pinLog={data.pin_history}
          pinTypeData={pinTypeData}
          pinOrderData={pinOrderData}
          pinStatusData={pinStatusData}
          pinWarrantyData={pinWarrantyData}
          appointment={appointment}
          intervention={data}
        />
      </Form>

      <PinOverview
        showPrintOverview={showPrintOverview}
        pin={latestPinData}
        pinLog={data.pin_history}
        appointment={appointment}
        intervention={data}
        pinStatusOptions={pinStatusData}
        pinTypeData={pinTypeData}
        pinOrderData={pinOrderData}
        pinWarrantyData={pinWarrantyData}
        onClose={closeModal}
      />
    </div>
  );
};
