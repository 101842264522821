import { saveAs } from "file-saver";
import moment from "moment";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Checkbox, CheckboxProps, Dropdown, Icon, Popup } from "semantic-ui-react";

import { DATE_FORMATS, FilePreview } from "components";
import "components/FileDropdown/FileDropdown.scss";
import { AppointmentNoteAttachment, SnoozeAttachment } from "models";
import { getNameFromURL } from "util/common";
import { ITranslation } from "util/interfaces";

export type BasicAttachment = {
  url: string;
  name: string;
  created_on?: string | null;
  updated_on?: string | null;
};

type FileDropdownProps = {
  attachments?: AppointmentNoteAttachment[] | SnoozeAttachment[] | BasicAttachment[];
  iconColor?: string;
};

export const FileDropdown = ({ attachments, iconColor = "#21A1D0" }: FileDropdownProps) => {
  if (!attachments?.length) return null;

  const t = useTranslation().t as ITranslation;

  const filePreviewContentRef = useRef<HTMLDivElement>(null);
  const [isPreviewVisible, setIsPreviewVisible] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [selectedFileUrls, setSelectedFileUrls] = useState<string[]>([]);

  const handlePreviewAttachment = (evt: React.MouseEvent<HTMLDivElement, MouseEvent>, index: number) => {
    evt.stopPropagation();

    setIsPreviewVisible(true);
    setCurrentIndex(index);
  };

  const handleClosePreviewModal = () => {
    setIsPreviewVisible(false);
  };

  useEffect(() => {
    filePreviewContentRef.current?.focus();
  }, [filePreviewContentRef.current]);

  const getIconFromFileType = (filename: string) => {
    const ext = filename.split(".")?.pop()?.toLowerCase() || "";
    if (ext === "pdf") {
      return "file pdf";
    } else if (["jpg", "jpeg", "png", "gif", "bmp"].includes(ext)) {
      return "image";
    } else if (["mp4", "mov", "avi", "wmv", "mkv"].includes(ext)) {
      return "video";
    } else {
      return "note";
    }
  };

  const handleSelectedFilesChekboxChange = (e: React.FormEvent<HTMLInputElement>, data: CheckboxProps) => {
    e.stopPropagation();

    const selectedFileUrlsUpdate = selectedFileUrls.includes(String(data.value))
      ? selectedFileUrls.filter(url => url !== String(data.value))
      : [...selectedFileUrls, String(data.value)];

    setSelectedFileUrls(selectedFileUrlsUpdate);
  };

  const handleDownloadFiles = () => {
    selectedFileUrls.forEach(url => {
      const file = attachments.find((attachment: AppointmentNoteAttachment | SnoozeAttachment) => attachment.url === url);
      if (file) {
        const fileName = "name" in file ? file.name : getNameFromURL(file.url);
        saveAs(file.url, fileName);
      }
    });
  };

  const handleSelectAll = (e: React.FormEvent<HTMLInputElement>) => {
    e.stopPropagation();

    const isAllSelected = selectedFileUrls.length === attachments.length;
    setSelectedFileUrls(isAllSelected ? [] : attachments.map(attachment => attachment.url));
  };

  return (
    <div className="AppointmentNoteAttachments">
      <Dropdown
        multiple
        className="action-dropdown"
        floating
        icon={null}
        trigger={
          <div className="appointment-note-attachments">
            <Icon className="paperclip" style={{ color: iconColor }} />
            <div>{attachments.length}</div>
          </div>
        }
      >
        <Dropdown.Menu>
          <Dropdown.Item>
            <div>
              <Checkbox checked={selectedFileUrls.length === attachments.length} onChange={handleSelectAll} className="files-url-checkbox" />
              <span>{t("v8_select_all").message || "Select all"}</span>
            </div>
          </Dropdown.Item>

          {attachments.map((attachment, index) => {
            const fileName = "name" in attachment ? attachment.name : getNameFromURL(attachment.url);
            return (
              <Dropdown.Item key={attachment.url} onClick={evt => handlePreviewAttachment(evt, index)}>
                <div className="dropdown-item-name-container">
                  <Checkbox
                    checked={selectedFileUrls.includes(String(attachment.url))}
                    value={String(attachment.url)}
                    onChange={handleSelectedFilesChekboxChange}
                    className="files-url-checkbox"
                  />

                  <Icon className={getIconFromFileType(fileName)} />
                  <Popup trigger={<span className="dropdown-title ellipsis">{fileName}</span>} content={fileName} />
                </div>
                <div className="dropdown-info">
                  {"username" in attachment && <p>{`${t("v8_uploaded_by").message || "uploaded by"} ${attachment.username}`}</p>}
                  {attachment.updated_on && <p>{moment(attachment.updated_on).format(DATE_FORMATS.dateMonthYearTime)}</p>}
                </div>
              </Dropdown.Item>
            );
          })}

          {selectedFileUrls.length > 0 && (
            <Button onClick={handleDownloadFiles} color="green" className="files-url-download-btn">
              {t("download").message || "Download"}
            </Button>
          )}
        </Dropdown.Menu>
      </Dropdown>

      <FilePreview attachments={attachments} currentIndex={currentIndex} open={isPreviewVisible} onClose={handleClosePreviewModal} />
    </div>
  );
};
