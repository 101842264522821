import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { Route, BrowserRouter as Router, Switch } from "react-router-dom";
import { ToastContainer } from "react-toastify";

import { Auth as AuthHook, Can, NotFoundPage, PrivateRoute, PublicRoute } from "components";
import { PrivateRouteLayout, PublicRouteLayout, RouteWithErrorBoundary } from "layouts";
import {
  AppointmentDetails,
  Appointments,
  Auth,
  CarDetails,
  CustomerCom,
  Dayplanner,
  DealerDocumentations,
  DealerLicenseOverview,
  DealerReports,
  ExactFailures,
  Leads,
  LocationLicenseOverview,
  LocationReports,
  NoteSuggestions,
  Styleguide
} from "modules";
import AccountSettings from "modules/Auth/components/AccountSettings";
import { PATHS } from "router/paths";

// eslint-disable-next-line no-relative-import-paths/no-relative-import-paths, import/order
import "react-toastify/dist/ReactToastify.css";

const App = () => {
  return (
    <>
      <ToastContainer position="top-right" autoClose={1500} closeOnClick draggable theme="light" />
      <Router>
        <AuthHook />
        <Switch>
          <RouteWithErrorBoundary exact path={[PATHS.HOME, PATHS.LOGIN, PATHS.RESET_PASSWORD, PATHS.RESET_PASSWORD_REQUEST]}>
            <PublicRouteLayout>
              <Switch>
                <PublicRoute exact path={[PATHS.HOME, PATHS.LOGIN, PATHS.RESET_PASSWORD, PATHS.RESET_PASSWORD_REQUEST]} component={Auth} />
              </Switch>
            </PublicRouteLayout>
          </RouteWithErrorBoundary>

          <RouteWithErrorBoundary
            exact
            path={[
              PATHS.APPOINTMENTS,
              PATHS.APPOINTMENT_DETAIL,
              PATHS.DAYPLANNER,
              PATHS.CAR_DETAILS,
              PATHS.STYLE_GUIDE,
              PATHS.CUSTOMER_COM,
              PATHS.ACCOUNT,
              PATHS.DEALER_DOCUMENTATION,
              PATHS.DEALER_REPORTS,
              PATHS.LOCATION_REPORTS,
              PATHS.EXACT_FAILURES,
              PATHS.LEADS_DASHBOARD,
              PATHS.LOCATION_LICENSE_OVERVIEW,
              PATHS.NOTES_MENU,
              PATHS.DEALER_LICENSE_OVERVIEW
            ]}
          >
            <PrivateRouteLayout>
              <Switch>
                <PrivateRoute
                  exact
                  path={PATHS.APPOINTMENTS}
                  render={() => (
                    <Can I="browse" the="appointments-page" renderNoAccess>
                      <Appointments />
                    </Can>
                  )}
                />
                <PrivateRoute
                  exact
                  path={PATHS.APPOINTMENT_DETAIL}
                  render={() => (
                    <Can I="browse" the="appointments-details-page" renderNoAccess>
                      <AppointmentDetails />
                    </Can>
                  )}
                />
                <PrivateRoute
                  exact
                  path={PATHS.DAYPLANNER}
                  render={() => (
                    <Can I="browse" the="dayplanner-page" renderNoAccess>
                      <Dayplanner />
                    </Can>
                  )}
                />
                <PrivateRoute
                  exact
                  path={PATHS.LEADS_DASHBOARD}
                  render={() => (
                    <Can I="browse" the="leads-dashboard-page" renderNoAccess>
                      <Leads />
                    </Can>
                  )}
                />
                <PrivateRoute
                  exact
                  path={PATHS.CAR_DETAILS}
                  render={() => (
                    <Can I="browse" the="appointments-car-page" renderNoAccess>
                      <CarDetails />
                    </Can>
                  )}
                />
                <PrivateRoute exact path={PATHS.STYLE_GUIDE} component={Styleguide} />
                <PrivateRoute
                  exact
                  path={PATHS.CUSTOMER_COM}
                  render={() => (
                    <Can I="browse" the="customer-comm-settings-page" renderNoAccess>
                      <CustomerCom />
                    </Can>
                  )}
                />
                <PrivateRoute
                  exact
                  path={PATHS.ACCOUNT}
                  render={() => (
                    <Can I="browse" the="account-page" renderNoAccess>
                      <AccountSettings />
                    </Can>
                  )}
                />
                <PrivateRoute
                  exact
                  path={PATHS.DEALER_DOCUMENTATION}
                  render={() => (
                    <Can I="browse" the="dealer-documentation-page" renderNoAccess>
                      <DealerDocumentations />
                    </Can>
                  )}
                />
                <PrivateRoute
                  exact
                  path={PATHS.DEALER_REPORTS}
                  render={() => (
                    <Can I="browse" the="reports-page" renderNoAccess>
                      <DealerReports />
                    </Can>
                  )}
                />
                <PrivateRoute
                  exact
                  path={PATHS.LOCATION_REPORTS}
                  render={() => (
                    <Can I="browse" the="locations-reports-page" renderNoAccess>
                      <LocationReports />
                    </Can>
                  )}
                />
                <PrivateRoute
                  exact
                  path={PATHS.EXACT_FAILURES}
                  render={() => (
                    <Can I="browse" the="exact-failures-page" renderNoAccess>
                      <ExactFailures />
                    </Can>
                  )}
                />
                <PrivateRoute
                  exact
                  path={PATHS.LOCATION_LICENSE_OVERVIEW}
                  render={() => (
                    <Can I="browse" the="exact-license-page" renderNoAccess>
                      <LocationLicenseOverview />
                    </Can>
                  )}
                />
                <PrivateRoute
                  exact
                  path={PATHS.NOTES_MENU}
                  render={() => (
                    <Can I="list" the="note-suggestions" renderNoAccess>
                      <NoteSuggestions />
                    </Can>
                  )}
                />
                <PrivateRoute
                  exact
                  path={PATHS.DEALER_LICENSE_OVERVIEW}
                  render={() => (
                    <Can I="browse" the="dealer-license-page" renderNoAccess>
                      <DealerLicenseOverview />
                    </Can>
                  )}
                />
              </Switch>
            </PrivateRouteLayout>
          </RouteWithErrorBoundary>

          <Route path="*" component={NotFoundPage} />
        </Switch>
      </Router>
      <ReactQueryDevtools initialIsOpen={false} />
    </>
  );
};

export default App;
