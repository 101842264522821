import { AnswerItem, Car, Customer, QuestionOption, SnoozeItem, TYRE_POSITIONS, Tag, Tyre, TyreReplacement, TyreResult } from "models";
import ApiLoadable from "models/ApiLoadable";

export enum QUESTION_STATUSES {
  NotApplicable = -1,
  OK = 0,
  OKWithRemarks,
  Advice,
  Necessary,
  NVT
}
export class QuestionResult extends ApiLoadable {
  id: number = 0;
  created_on: string = "";
  updated_on: string = "";
  group_name: string = "";
  group_order: number = 0;
  question_order: number = 0;
  status: number = 0;
  title: string = "";
  completed: boolean = false;
  mechanic_notes: string = "";
  mechanic_fixed: boolean = false;
  customer_approved: boolean = false;
  is_signed_by_customer: boolean = false;
  price: number = 0;
  raw: string = "";
  solution: string = "";
  solution_value: string = "";
  solution_type: string = "";
  solution_unit: string = "";
  snooze_date: string | null = null;
  snooze_note: string = "";
  snooze_type: number | null = null;
  note: string = "";
  type: number = 0;
  snoozed: boolean = false;
  pinned: boolean = false;
  pin_comment?: string = "";
  question_id: number = 0;
  appointment_id: number = 0;
  car_id: number = 0;
  check_id: number = 0;
  dealer_location_id: number = 0;
  tyre?: Tyre | null = null;
  tyre_position?: TYRE_POSITIONS;
  tyre_profile?: string;
  truck_axle: number = 0;
  checkin_remark_id?: number | null;
  checkin_result_id?: number | null;

  private _tyre_replacements?: TyreReplacement[] | null = null;

  get tyre_replacements(): TyreReplacement[] {
    return this._tyre_replacements ? this._tyre_replacements : [];
  }

  set tyre_replacements(tyre_replacements: TyreReplacement[] | null | undefined) {
    this._tyre_replacements = tyre_replacements;
  }

  private _snooze_history?: SnoozeItem[] | null = null;

  get snooze_history(): SnoozeItem[] {
    return this._snooze_history ? this._snooze_history : [];
  }

  set snooze_history(snooze_history: SnoozeItem[] | null | undefined) {
    this._snooze_history = snooze_history;
  }

  private _images?: QuestionResultImage[] | null = null;

  get images(): QuestionResultImage[] {
    return this._images ? this._images : [];
  }

  set images(images: QuestionResultImage[] | null | undefined) {
    this._images = images;
  }

  private _videos?: QuestionResultVideo[] | null = null;

  get videos(): QuestionResultVideo[] {
    return this._videos ? this._videos : [];
  }

  set videos(video: QuestionResultVideo[] | null | undefined) {
    this._videos = video;
  }

  private _answers?: AnswerItem[] | null = null;

  get answers(): AnswerItem[] {
    return this._answers ? this._answers : [];
  }

  set answers(answers: AnswerItem[] | null | undefined) {
    this._answers = answers;
  }

  private _tyre_on_car?: TyreResult[] | null = null;

  get tyre_on_car(): TyreResult[] {
    return this._tyre_on_car ? this._tyre_on_car : [];
  }

  set tyre_on_car(tyre_on_car: TyreResult[] | null | undefined) {
    this._tyre_on_car = tyre_on_car;
  }

  private _tyre_in_storage?: TyreResult[] | null = null;

  get tyre_in_storage(): TyreResult[] {
    return this._tyre_in_storage ? this._tyre_in_storage : [];
  }

  set tyre_in_storage(tyre_in_storage: TyreResult[] | null | undefined) {
    this._tyre_in_storage = tyre_in_storage;
  }

  private _question_options?: QuestionOption[] | null = null;

  get question_options(): QuestionOption[] {
    return this._question_options ? this._question_options : [];
  }

  set question_options(question_options: QuestionOption[] | null | undefined) {
    this._question_options = question_options;
  }

  checklist_id: number = 0;
  checklist_name: string = "";
  time_car_app: string = "";
  wo_nr: string = "";
  reg_number: string = "";
  owner_id?: number | null = null;
  is_quality?: boolean = false;
  driver_id?: number | null = null;
  contractor_id?: number | null = null;
  owner?: Customer | null = null;
  driver?: Customer | null = null;
  contractor?: Customer | null = null;
  car?: Car | null = null;

  private _checklist_tags?: Tag[] | null = null;

  get checklist_tags(): Tag[] {
    return this._checklist_tags ? this._checklist_tags : [];
  }

  set checklist_tags(checklist_tags: Tag[] | null | undefined) {
    this._checklist_tags = checklist_tags;
  }

  private _question_tags?: Tag[] | null = null;

  get question_tags(): Tag[] {
    return this._question_tags ? this._question_tags : [];
  }

  set question_tags(question_tags: Tag[] | null | undefined) {
    this._question_tags = question_tags;
  }
}

export class QuestionResultImage extends ApiLoadable {
  id: number = 0;
  created_on: string = "";
  updated_on: string = "";
  url: string = "";
  active: boolean = false;
  visible_in_pdf: boolean = false;
  is_final_check: boolean = false;
  intervention_index: number = 0;
  tyre_position: number = 0;
  question_result_id: number = 0;
  type: string = "";
}

export class QuestionResultVideo extends ApiLoadable {
  id: number = 0;
  created_on: string = "";
  updated_on: string = "";
  url: string = "";
  active: boolean = false;
  visible: boolean = false;
  is_final_check: boolean = false;
  intervention_index: number = 0;
  question_result_id: number = 0;
  type: string = "";
}
