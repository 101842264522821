import { useQueryClient } from "@tanstack/react-query";
import { createColumnHelper } from "@tanstack/react-table";
import _startCase from "lodash/startCase";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Icon } from "semantic-ui-react";

import { ReactTable } from "components";
import { Appointment, DealerLocation, LeadsCars } from "models";
import { CustomerCard, TableDropdownSelector } from "modules/LeadsDashboard/components";
import "modules/LeadsDashboard/Leads/CarsLeads/components/CarsLeadsTable/CarsLeadsTable.scss";
import { useCarsTableFiltersHandlers } from "modules/LeadsDashboard/Leads/CarsLeads/hooks";
import { LeadsQueryKeys } from "modules/LeadsDashboard/queryKeys";
import { getFormattedDate, getLocationName } from "modules/LeadsDashboard/util";
import { PATHS } from "router/paths";
import { ITranslation } from "util/interfaces";

type CarsLeadsTableProps = {
  locations: DealerLocation[];
};

const PAGE_SIZE = 100;

export const CarsLeadsTable = ({ locations }: CarsLeadsTableProps) => {
  const t = useTranslation().t as ITranslation;
  const queryClient = useQueryClient();
  const carsLeads = queryClient.getQueryData<LeadsCars>([LeadsQueryKeys.listCars]);
  const { handleOnLeaseSelect, selectedLeaseCompanyOption, leaseCompanyOptions, currentPage, handlePaginationSelect } = useCarsTableFiltersHandlers({
    pageSize: PAGE_SIZE
  });

  const columnHelper = createColumnHelper<Appointment>();

  const columns = [
    columnHelper.accessor((row: Appointment) => row.time_car_app, {
      id: "appointment_date",
      header: () => <span className="no-wrap-text table-header-text">{t("v8_appointment_date").message || "Appointment Date"} </span>,
      size: 160,
      cell: ({ renderValue }) => <span className="ellipsis ellipsis-wo-nr">{getFormattedDate(renderValue())}</span>
    }),

    columnHelper.accessor((row: Appointment) => row, {
      id: "wo",
      header: () => <span className="no-wrap-text table-header-text">WO #</span>,
      size: 50,
      cell: ({ getValue }) => {
        const { id, wo_nr } = getValue();
        return (
          <p className="ellipsis">
            <Link to={`${PATHS.APPOINTMENTS}/${id}`}>{wo_nr}</Link>
          </p>
        );
      }
    }),

    columnHelper.accessor((row: Appointment) => row, {
      id: "reg_number",
      header: () => (
        <div className="appointment-table-wrapper">
          <span className="ellipsis ellipsis-wo-nr table-header-text"> {t("v8_reg_number").message || "Reg #"}</span>
          <TableDropdownSelector
            options={leaseCompanyOptions}
            multiple={false}
            withSearch={false}
            selected={[selectedLeaseCompanyOption]}
            handleOnSelect={handleOnLeaseSelect}
          />
        </div>
      ),
      size: 50,
      cell: ({ getValue }) => {
        const { car_id, is_lease_company, reg_number } = getValue();
        return (
          <p className="ellipsis ">
            <Link to={`${PATHS.CAR_DETAIL}/${car_id}`}>
              {reg_number}
              {is_lease_company && <Icon name="building" />}
            </Link>
          </p>
        );
      }
    }),

    columnHelper.accessor((row: Appointment) => row.dealer_location_id, {
      id: "location_name",
      header: () => <span className="no-wrap-text table-header-text">{_startCase(t("v8_location").message || "Location")}</span>,
      size: 135,
      cell: ({ renderValue }) => <span className="ellipsis ellipsis-wo-nr">{getLocationName(renderValue(), locations)}</span>
    }),

    columnHelper.accessor((row: Appointment) => row.current_km, {
      id: "current_km",
      header: () => <span className="no-wrap-text table-header-text">{_startCase(t("v8_current_km").message || "Current Km")} </span>,
      size: 90,
      cell: ({ renderValue }) => <span className="ellipsis ellipsis-wo-nr">{renderValue()}</span>
    }),

    columnHelper.accessor((row: Appointment) => row.next_km, {
      id: "next_km",
      header: () => <span className="no-wrap-text table-header-text"> {_startCase(t("v8_next_km").message || "Next Km")}</span>,
      size: 70,
      cell: ({ renderValue }) => <span className="ellipsis ellipsis-wo-nr">{renderValue()}</span>
    }),

    columnHelper.accessor((row: Appointment) => row.next_date, {
      id: "next_date",
      header: () => <span className="no-wrap-text table-header-text"> {_startCase(t("v8_next_date").message || "Next Date")} </span>,
      size: 90,
      cell: ({ renderValue }) => <span className="ellipsis ellipsis-wo-nr">{getFormattedDate(renderValue())}</span>
    }),

    columnHelper.accessor((row: Appointment) => row.predicted_next_date, {
      id: "predicted_next_date",
      header: () => <span className="no-wrap-text table-header-text"> {_startCase(t("v8_predicted_next_date").message || "Predicted Next Date")} </span>,
      size: 235,
      cell: ({ renderValue }) => <p className="ellipsis ellipsis-wo-nr">{getFormattedDate(renderValue())}</p>
    }),

    columnHelper.accessor((row: Appointment) => row, {
      id: "status",
      header: () => null,
      size: 30,
      cell: ({ getValue }) => {
        const { customer_driver, customer_owner, customer_contract } = getValue();
        return (
          <div className="car-leads-table-customer-card-icon">
            <CustomerCard contractor={customer_contract} owner={customer_owner} driver={customer_driver} />
          </div>
        );
      }
    })
  ];

  return (
    <div className="table-container">
      <ReactTable
        columns={columns}
        data={carsLeads?.items ?? []}
        pageCount={carsLeads?.nb_pages}
        pageSize={PAGE_SIZE}
        totalItems={carsLeads?.nb_items}
        pagination={carsLeads?.nb_pages && carsLeads.nb_pages > 1 ? { pageIndex: currentPage - 1, pageSize: PAGE_SIZE } : undefined}
        onPaginationChange={handlePaginationSelect}
      />
    </div>
  );
};
