import { useMemo } from "react";
import { Icon } from "semantic-ui-react";

import { ANSWER_STATUS_SELECTOR_TYPE, ActionModalSelector, ActionModalSelectorData, AnswerStatusSelector } from "components";
import { Appointment, Intervention, SnoozeAttachment } from "models";
import { QuestionResult } from "models/QuestionResult";
import { GenericAttachmentData } from "modules/AppointmentDetails/components/Interventions/components";

type SnoozeActionsProps = {
  data: QuestionResult | Intervention;
  isIntervention: boolean;
  attachments?: SnoozeAttachment[];
  onSelect: (data: ActionModalSelectorData) => void;
  toggleShowMedia?: () => void;
  appointment: Appointment;
  onUpdateAnswerStatus?: (id: number, status: number, type: ANSWER_STATUS_SELECTOR_TYPE) => void;
};

const SnoozeActions = ({ data, appointment, attachments, onSelect, toggleShowMedia, isIntervention, onUpdateAnswerStatus }: SnoozeActionsProps) => {
  const isConvertToInterventionButtonDisabled = useMemo(() => {
    if (isIntervention) return true;
    return appointment?.interventions?.some(intervention => intervention.question_result_id === data.id);
  }, [appointment.interventions, data.id]);
  return (
    <div className="snooze-action">
      <div className="status-container">
        <AnswerStatusSelector
          data={data}
          type={isIntervention ? ANSWER_STATUS_SELECTOR_TYPE.intervention : ANSWER_STATUS_SELECTOR_TYPE.questionResult}
          onUpdateAnswerStatus={onUpdateAnswerStatus}
        />
        {!!attachments?.length && toggleShowMedia && (
          <div onClick={toggleShowMedia}>
            <Icon className="images grey pointer" size="small" />
            <p>
              <small>{attachments.length}</small>
            </p>
          </div>
        )}
        {!isIntervention && (
          <ActionModalSelector
            data={data}
            attachments={attachments as GenericAttachmentData[]}
            onSelect={onSelect}
            editSnooze
            isConvertToInterventionButtonDisabled={isConvertToInterventionButtonDisabled}
          />
        )}
      </div>
      {isIntervention && <div className="empty-status-container" />}
    </div>
  );
};

export default SnoozeActions;
