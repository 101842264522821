import { createColumnHelper } from "@tanstack/react-table";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { Popup } from "semantic-ui-react";

import { DATE_FORMATS, DetailsCards, ReactTable, Snooze, TabData, useGetSnoozeStatusData, useGetSnoozeTypeData } from "components";
import "modules/CarDetails/components/SnoozedItems/SnoozedItems.scss";
import { SnoozeItemWithHistory } from "modules/CarDetails/utils";
import { ITranslation } from "util/interfaces";

type SnoozedItemsProps = {
  tabData: TabData;
  isExpanded: boolean;
};

export const SnoozedItems = ({ tabData, isExpanded }: SnoozedItemsProps) => {
  const t = useTranslation().t as ITranslation;

  const { allSnoozeTypeData } = useGetSnoozeTypeData();
  const snoozeStatusData = useGetSnoozeStatusData();

  const columnHelper = createColumnHelper<any>();
  const columns = [
    columnHelper.accessor(row => row.appointment.created_on, {
      id: "date",
      header: t("v8_appointment_date").message || "Appointment Date",
      cell: info => moment(info.renderValue()).format(DATE_FORMATS.dateMonthYear)
    }),
    columnHelper.accessor(row => row.appointment.wo_nr, {
      id: "workOrder",
      header: "WO #",
      cell: info => info.renderValue()
    }),
    columnHelper.accessor(row => row.snooze_date, {
      id: "snoozedDate",
      header: t("v8_snooze_date").message || "Snooze Date",
      cell: info => {
        const snoozeData = info.renderValue();
        return snoozeData ? moment(snoozeData).format(DATE_FORMATS.dateMonthYear) : null;
      }
    }),
    columnHelper.accessor(row => row.snooze_status_id, {
      id: "snoozedStatus",
      header: t("v8_snooze_status").message || "Snooze Status",
      cell: info => {
        const snoozeStatusId = info.renderValue();
        const snoozeData = snoozeStatusData.find(snooze => snooze.value === snoozeStatusId);
        return snoozeData?.text || "";
      }
    }),
    columnHelper.accessor(row => row.snooze_type_id, {
      id: "task",
      header: t("v8_task").message || "Task",
      cell: info => {
        const snoozeTypeId = info.renderValue();
        return allSnoozeTypeData.find(snooze => snooze.value === snoozeTypeId)?.text || "";
      }
    }),
    columnHelper.accessor(row => row.question_result?.title, {
      id: "intervention",
      header: t("v8_intervention_question").message || "Intervention/Question",
      cell: info => <Popup hoverable content={info.renderValue()} trigger={<p className="ellipsis medium">{info.renderValue()}</p>} />,
      size: 180
    }),
    columnHelper.accessor(row => row.note, {
      id: "note",
      header: t("v8_note").message || "Note",
      cell: info => <Popup hoverable content={info.renderValue()} trigger={<p className="ellipsis">{info.renderValue()}</p>} />
    }),
    columnHelper.accessor(row => row, {
      id: "email",
      header: "",
      cell: info => <Snooze data={info.renderValue() as SnoozeItemWithHistory} showOwnerInfo />
    })
  ];

  return (
    <DetailsCards
      containerStyle="mt-20"
      isExpanded={isExpanded}
      id={tabData.id}
      icon="clock"
      title={`${t("v8_snoozed_items").message || "Snoozed Items"} ${tabData.data.length > 0 ? `(${tabData.data.length})` : ""}`}
    >
      <div className="SnoozedItems">
        <ReactTable columns={columns} data={tabData.data} />
      </div>
    </DetailsCards>
  );
};
