import { useQuery, useQueryClient } from "@tanstack/react-query";
import moment from "moment";
import { useEffect } from "react";

import { useDealersLocations } from "hooks";
import { QUESTION_STATUSES } from "models";
import { PossibleQuestionStatusOptions } from "modules/LeadsDashboard/hooks";
import { LeadsSnoozedFilters } from "modules/LeadsDashboard/Leads/SnoozedLeads/components";
import { LeadsQueryKeys } from "modules/LeadsDashboard/queryKeys";

export const QUESTION_STATUSES_DEFAULT_STATUSES: PossibleQuestionStatusOptions[] = [
  QUESTION_STATUSES.OKWithRemarks,
  QUESTION_STATUSES.Advice,
  QUESTION_STATUSES.Necessary
];

export const useDefaultSnoozedFilters = () => {
  const queryClient = useQueryClient();
  const { selectedLocation } = useDealersLocations();

  const defaultSnoozedFilters: LeadsSnoozedFilters = {
    page: 1,
    dealer_ids: [],
    appointment_date_from: moment().subtract(30, "days").toDate(),
    appointment_date_to: moment().toDate(),
    search_term: "",
    is_lease_company: null,
    location_ids: selectedLocation?.id ? [selectedLocation.id] : [],
    customer_approved: false,
    interventions: false,
    statuses: QUESTION_STATUSES_DEFAULT_STATUSES,
    questions: null,
    tags: null,
    snooze_date_from: null,
    snooze_date_to: null,
    snooze_department_id: null,
    snooze_status_id: null,
    snooze_type_id: null,
    last_customer_ok_is_me: false
  };

  useEffect(() => {
    const snoozedFilters = queryClient.getQueryData<LeadsSnoozedFilters>(LeadsQueryKeys.snoozedFilters);
    if (!selectedLocation || (snoozedFilters?.location_ids && snoozedFilters?.location_ids.length > 0)) return;

    queryClient.setQueryData(LeadsQueryKeys.snoozedFilters, { ...defaultSnoozedFilters, location_ids: selectedLocation ? [selectedLocation.id] : [] });
  }, [selectedLocation]);

  return { defaultSnoozedFilters };
};

export const useGetSnoozedFilters = () => {
  const { defaultSnoozedFilters } = useDefaultSnoozedFilters();
  const { data: snoozedFilters } = useQuery<LeadsSnoozedFilters>({ queryKey: LeadsQueryKeys.snoozedFilters, initialData: defaultSnoozedFilters });
  return { snoozedFilters };
};
