import moment from "moment";
import { useState } from "react";
import ReactDatePicker from "react-datepicker";
import { useTranslation } from "react-i18next";
import { Button, Icon, Modal, Radio, Search, SearchResultData, Form as SemanticForm } from "semantic-ui-react";

import { DATE_FORMATS, Form, FormInput } from "components";
import { useDealersLocations } from "hooks";
import { ExactCategory, ExactCategoryLicense, ExactStandaloneLicense } from "models";
import "modules/LocationLicenseOverview/components/CategoryLicenseModal/CategoryLicenseModal.scss";
import { useSelectedCategoryLicenseUpdate } from "modules/LocationLicenseOverview/hooks";
import { CategoryModalLicense, INVOICING_OPTIONS, LOCATION_LICENSE_SUBPAGE, LicenseData, LicenseItem, Licenses } from "modules/LocationLicenseOverview/types";
import { filterLicenseData, findExactItemById, getLicenseCategoryName, getNextMonthlyInvoicingDate } from "modules/LocationLicenseOverview/utils";
import { ITranslation } from "util/interfaces";

type CategoryLicenseModalProps = {
  onClose: () => void;
  isOpen: boolean;
  selectedLicense: LicenseData | null;
  items: LicenseItem[];
  selectedTab: LOCATION_LICENSE_SUBPAGE;
  isLoadingLicenses: boolean;
  licensesData: Licenses | undefined;
};

const defaultLicenseState: CategoryModalLicense = {
  search: "",
  searchedItems: [],
  item: null,
  description: null,
  price: 0,
  categoryLicenseID: 0,
  isSearchDropdownOpen: false
};

export const CategoryLicenseModal = ({ onClose, isOpen, selectedLicense, items, selectedTab, isLoadingLicenses, licensesData }: CategoryLicenseModalProps) => {
  const t = useTranslation().t as ITranslation;
  const [licenses, setLicenses] = useState<CategoryModalLicense[]>([defaultLicenseState]);
  const [selectedInvoicingOption, setSelectedInvoicingOption] = useState<INVOICING_OPTIONS | null>(null);
  const [customInvoicingDate, setCustomInvoicingDate] = useState<Date | null>(null);
  const [category, setCategory] = useState<ExactCategory | null>(null);
  const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);
  const [hasValidationError, setHasValidationError] = useState(false);

  const categoryLicenceUpdateMutation = useSelectedCategoryLicenseUpdate();
  const { selectedLocation } = useDealersLocations();

  const addLicenseRow = () => {
    setLicenses(prevLicenses =>
      prevLicenses
        .map(license => ({
          ...license,
          isSearchDropdownOpen: false
        }))
        .concat({ ...defaultLicenseState })
    );
  };

  const handleItemClick = (data: SearchResultData, index: number) => {
    const item: LicenseItem = data?.result;
    setLicenses(prevLicenses => {
      const updatedLicenses = [...prevLicenses];
      updatedLicenses[index] = {
        ...updatedLicenses[index],
        item,
        search: `${item.Code} - ${item.Description}`,
        price: item?.StandardSalesPrice || 0,
        searchedItems: [],
        isSearchDropdownOpen: false
      };
      return updatedLicenses;
    });
  };

  const handlePrice = (value: number, index: number) => {
    setLicenses(prevLicenses => {
      const updatedLicenses = [...prevLicenses];
      updatedLicenses[index] = {
        ...updatedLicenses[index],
        price: value,
        isSearchDropdownOpen: false
      };
      return updatedLicenses;
    });
  };

  const handleDescription = (value: string, index: number) => {
    setLicenses(prevLicenses => {
      const updatedLicenses = [...prevLicenses];
      updatedLicenses[index] = {
        ...updatedLicenses[index],
        description: value || "",
        isSearchDropdownOpen: false
      };
      return updatedLicenses;
    });
  };

  const deleteRow = (index: number) => {
    setLicenses(prevLicenses =>
      prevLicenses
        .map(license => ({
          ...license,
          isSearchDropdownOpen: false
        }))
        .filter((_, i) => i !== index)
    );
  };

  const closeModal = () => {
    setLicenses([defaultLicenseState]);
    setSelectedInvoicingOption(null);
    setCustomInvoicingDate(null);
    setCategory(null);
    setIsDatePickerOpen(false);
    setHasValidationError(false);
    onClose();
  };

  const getCurrentCategory = (categories: ExactCategory[] | undefined) => {
    return categories?.find(cat => cat.id === selectedLicense?.categoryID && (cat.tablet_model || "") === (selectedLicense?.tablet_model || ""));
  };

  const setInvoicingData = () => {
    if (selectedTab === LOCATION_LICENSE_SUBPAGE.MonthlyLicenses) {
      setSelectedInvoicingOption(INVOICING_OPTIONS.Monthly);
    } else {
      if (selectedLicense?.invoiceOn) {
        setCustomInvoicingDate(selectedLicense.invoiceOn);
        setSelectedInvoicingOption(INVOICING_OPTIONS.OnceOnDate);
      } else {
        setSelectedInvoicingOption(INVOICING_OPTIONS.OnceInBillingCycle);
      }
    }
  };

  const handleOpenModal = () => {
    if (!selectedLicense?.categoryID) return;

    const currentCategory = getCurrentCategory(
      selectedTab === LOCATION_LICENSE_SUBPAGE.MonthlyLicenses ? licensesData?.monthlyCategories : licensesData?.oneTimeCategories
    );
    if (!currentCategory) return;

    setInvoicingData();
    setCategory(currentCategory);

    if (!currentCategory.licenses) return;

    const cls = currentCategory.licenses.map((categoryLicense: ExactCategoryLicense) => {
      const item = findExactItemById(items, categoryLicense.exact_item_id);
      return {
        price: categoryLicense.price,
        categoryLicenseID: categoryLicense.id,
        item: item || null,
        description: categoryLicense.description || "",
        search: item ? `${item.Code} - ${item.Description}` : "",
        searchedItems: [],
        isSearchDropdownOpen: false
      } as CategoryModalLicense;
    });
    setLicenses(cls);
  };

  const handleSearch = (index: number, value: string) => {
    const filteredValues = filterLicenseData(items, value);
    setLicenses(prevLicenses =>
      prevLicenses.map((license, idx) => ({
        ...license,
        isSearchDropdownOpen: idx === index,
        search: idx === index ? value : license.search,
        searchedItems: idx === index ? filteredValues : license.searchedItems
      }))
    );
  };

  const validateRequest = () => {
    if (selectedInvoicingOption === null || licenses.some(license => !license.item || !license.price)) {
      setHasValidationError(true);
      return false;
    }

    setHasValidationError(false);
    return true;
  };

  const prepareSaveParams = () => {
    if (selectedInvoicingOption === null || !selectedLocation || !category) return;
    const validLicenses: ExactCategoryLicense[] = [];
    licenses.forEach(license => {
      if (!license.price) return;
      const exactLicense: ExactCategoryLicense = {
        price: license.price,
        category_id: category.id,
        description: license.description || "",
        exact_item_id: license.item?.ID || "",
        id: license.categoryLicenseID || 0,
        updated_on: new Date().toISOString()
      };
      validLicenses.push(exactLicense);
    });

    const invoiceOn =
      selectedInvoicingOption !== INVOICING_OPTIONS.Monthly
        ? selectedInvoicingOption === INVOICING_OPTIONS.OnceOnDate
          ? customInvoicingDate
          : getNextMonthlyInvoicingDate().startOf("day").toDate()
        : null;

    return {
      category: {
        ...category,
        dealer_location_id: selectedLocation.id,
        licenses: validLicenses,
        invoice_on: invoiceOn,
        one_time: selectedInvoicingOption !== INVOICING_OPTIONS.Monthly
      }
    };
  };

  const handleSave = async () => {
    const isValid = validateRequest();
    if (!isValid) return;

    const params = prepareSaveParams();
    if (!params) return;

    await categoryLicenceUpdateMutation.mutateAsync(params);
    closeModal();
  };

  const handleChangeDate = (date: Date | null) => {
    if (!date) return;

    setCustomInvoicingDate(date);
    setSelectedInvoicingOption(INVOICING_OPTIONS.OnceOnDate);
    setIsDatePickerOpen(false);
  };

  const getModalTitle = () => {
    const categoryType = category?.license_category_type ? ` - ${getLicenseCategoryName(category.license_category_type, t)}` : "";
    const subPageTitle = selectedTab === LOCATION_LICENSE_SUBPAGE.MonthlyLicenses ? t("v8_monthly").message || "Monthly" : t("v8_once").message || "Once";
    return `${t("v8_link_license").message || "Link license"}${categoryType} - ${subPageTitle}`;
  };

  return (
    <Modal open={isOpen} size="large" onClose={closeModal} onMount={handleOpenModal} closeOnDimmerClick={false} className="CategoryLicenseModal">
      <Modal.Header>{getModalTitle()}</Modal.Header>
      <Form<ExactStandaloneLicense> onSubmit={handleSave} formProps={{ loading: isLoadingLicenses }}>
        <div className="content">
          {licenses.map((license, index) => (
            <SemanticForm.Group key={`${license.categoryLicenseID - index}`}>
              <SemanticForm.Field className="dropdown-search" width={7} error={hasValidationError && !license.search} required>
                {index < 1 && <label className="big">{t("v8_license").message || "License"}</label>}
                <div className="search">
                  <Search
                    fluid={true}
                    placeholder={t("v8_search_for_license").message || "Search for license"}
                    icon="magnifying glass"
                    noResultsMessage={t("v8_no_results").message || "No results found"}
                    onSearchChange={(_e, { value }) => handleSearch(index, value || "")}
                    onResultSelect={(_e, data) => handleItemClick(data, index)}
                    resultRenderer={({ Code, Description }) => <p>{`${Code} - ${Description}`}</p>}
                    results={license.searchedItems}
                    value={license.search}
                    open={license.isSearchDropdownOpen}
                  />
                  <Button color="green" type="button" className="mt-14 rowButton" onClick={() => handleSearch(index, license.search)}>
                    {t("v8_search").message || "Search"}
                  </Button>
                </div>
              </SemanticForm.Field>
              <SemanticForm.Field width={6}>
                {index < 1 && <label>{t("v8_overwrite_name").message || "Overwrite name"}</label>}
                <FormInput<ExactStandaloneLicense>
                  inputProps={{
                    fluid: true,
                    value: license.description,
                    onChange: e => handleDescription(e.target.value, index)
                  }}
                  name="description"
                />
              </SemanticForm.Field>
              <SemanticForm.Field width={2} error={hasValidationError && !license.price} required>
                {index < 1 && <label>{t("v8_unit_price").message || "Unit price"}</label>}
                <FormInput<ExactStandaloneLicense>
                  inputProps={{
                    fluid: true,
                    value: license.price,
                    type: "number",
                    onChange: e => handlePrice(Number(e.target.value), index),
                    label: { basic: true, content: "€" },
                    labelPosition: "left"
                  }}
                  name="price"
                />
              </SemanticForm.Field>
              <SemanticForm.Field width={1}>
                <Button onClick={() => deleteRow(index)} type="button" className="wdLightGrey centerIcon greyBorder delete-license">
                  <Icon className={`trash red`} />
                </Button>
              </SemanticForm.Field>
            </SemanticForm.Group>
          ))}
          <SemanticForm.Group>
            <SemanticForm.Field width={16}>
              <Button icon labelPosition="left" color="green" type="button" onClick={addLicenseRow} className="addLicenseButton">
                <Icon className="plus" />
                {t("v8_add_license").message || "Add license"}
              </Button>
            </SemanticForm.Field>
          </SemanticForm.Group>
          {selectedTab !== LOCATION_LICENSE_SUBPAGE.MonthlyLicenses && (
            <>
              <SemanticForm.Group>
                <SemanticForm.Field width={16} className="mt-20" error={hasValidationError && selectedInvoicingOption === null} required>
                  <label>{t("v8_billing_options").message || "Billing options"}</label>
                </SemanticForm.Field>
              </SemanticForm.Group>
              <SemanticForm.Group>
                <SemanticForm.Field className="radio-button" width={16}>
                  <label>{`${t("v8_once_on_date").message || "Once, on date"}: ${customInvoicingDate ? moment(customInvoicingDate).format(DATE_FORMATS.dateMonthYear) : ""}`}</label>
                  <ReactDatePicker
                    selected={customInvoicingDate ? moment(customInvoicingDate).toDate() : new Date()}
                    onChange={handleChangeDate}
                    showMonthDropdown
                    showYearDropdown
                    minDate={moment().toDate()}
                    dateFormat="dd-MM-yyyy"
                    onClickOutside={() => setIsDatePickerOpen(false)}
                    onCalendarClose={() => setIsDatePickerOpen(false)}
                    onCalendarOpen={() => setIsDatePickerOpen(true)}
                    withPortal
                    inline={false}
                    open={isDatePickerOpen}
                    customInput={
                      <Radio
                        name="billingOptions"
                        value={INVOICING_OPTIONS.OnceOnDate}
                        checked={selectedInvoicingOption === INVOICING_OPTIONS.OnceOnDate}
                        onClick={() => setIsDatePickerOpen(true)}
                      />
                    }
                  />
                </SemanticForm.Field>
              </SemanticForm.Group>
              <SemanticForm.Group>
                <SemanticForm.Field className="radio-button" width={16}>
                  <label>{t("v8_once_on_next_months_billing_date").message || "Once, on next month's billing date"}</label>
                  <Radio
                    name="billingOptions"
                    value={INVOICING_OPTIONS.OnceInBillingCycle}
                    checked={selectedInvoicingOption === INVOICING_OPTIONS.OnceInBillingCycle}
                    onChange={() => setSelectedInvoicingOption(INVOICING_OPTIONS.OnceInBillingCycle)}
                  />
                </SemanticForm.Field>
              </SemanticForm.Group>
            </>
          )}
        </div>
        <div className="actions">
          <div className="action-buttons">
            <Button color="light" onClick={closeModal}>
              {t("v8_cancel").message || "Cancel"}
              <Icon className="xmark" />
            </Button>
            <Button color="green" type="submit" disabled={categoryLicenceUpdateMutation.isPending} loading={categoryLicenceUpdateMutation.isPending}>
              {t("v8_save").message || "Save"}
              <Icon className="check" />
            </Button>
          </div>
        </div>
      </Form>
    </Modal>
  );
};
